import { ReactNode } from "react";

import { Stack } from "@mui/material";

export type Props = {
  children: ReactNode;
};

const AuthenticationLayout = ({ children }: Props) => {
  return (
    <Stack
      sx={{
        width: "full",
        minHeight: "100vh",
        paddingTop: "20px",
        paddingX: "20px",
      }}
    >
      {children}
    </Stack>
  );
};

export default AuthenticationLayout;
