import React, { useContext, useState } from "react";

import { mediaQuery, useMediaQuery } from "hooks/useMediaQuery";

type MobileTypeContextType = {
  isPC: boolean;
  isDisplay: boolean;
  setIsDisplay: (isDisplay: boolean) => void;
};

export const MobileTypeContext = React.createContext<
  MobileTypeContextType | undefined
>(undefined);

export const useMobileTypeContext = () => useContext(MobileTypeContext);

export const MobileTypeContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [isDisplay, setIsDisplay] = useState<boolean>(false);
  const isPC = useMediaQuery(mediaQuery.pc);
  return (
    <MobileTypeContext.Provider value={{ isPC, isDisplay, setIsDisplay }}>
      {children}
    </MobileTypeContext.Provider>
  );
};
