/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * inojin-stg-api
 * OpenAPI spec version: 1.0.0
 */

/**
 * 保険証画像の表裏の識別子
0: 表
1: 裏
 */
export type PutPatientInsuranceCardsBodiesIndex = typeof PutPatientInsuranceCardsBodiesIndex[keyof typeof PutPatientInsuranceCardsBodiesIndex];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PutPatientInsuranceCardsBodiesIndex = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;
