import React from "react";

import { PaymentMethod } from "components/organisms/PaymentMethod";
import AuthenticationLayout from "components/template/AuthenticationLayout";

const AddPaymentMethodPage: React.FC<unknown> = () => {
  return (
    <AuthenticationLayout>
      <PaymentMethod />
    </AuthenticationLayout>
  );
};

export default AddPaymentMethodPage;
