import React from "react";

import { UserRegister } from "components/organisms/UserRegister";
import AuthenticationLayout from "components/template/AuthenticationLayout";

const UserRegisterPage: React.FC = () => {
  return (
    <AuthenticationLayout>
      <UserRegister />
    </AuthenticationLayout>
  );
};

export default UserRegisterPage;
