import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

type I18nContextType = {
  language: string;
  changeLanguage: (lang: string) => void;
  availableLanguages: string[];
};

const I18nContext = createContext<I18nContextType | undefined>(undefined);

export const I18nProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem('language');
    return savedLanguage || 'ja';
  });
  const availableLanguages = ['ja', 'en', 'zh'];

  useEffect(() => {
    i18n.changeLanguage(language);

    // eslint-disable-next-line
  }, []);

  // 言語設定の保持にローカルストレージを使う
  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
    localStorage.setItem('language', lang);
  };

  // i18nの初期化が確実に完了してからアプリケーションをレンダリングさせるため
  useEffect(() => {
    if (!i18n.isInitialized) {
      console.log('i18n is not initialized');
    }
  }, [i18n.isInitialized]);

  return (
    <I18nContext.Provider value={{ language, changeLanguage, availableLanguages }}>
      {children}
    </I18nContext.Provider>
  );
};

export const useI18n = () => {
  const context = useContext(I18nContext);
  if (context === undefined) {
    throw new Error('useI18n must be used within an I18nProvider');
  }
  return context;
};

