import React from "react";

import { useNavigates } from "hooks/useNavigates";
import { useSetIntents } from "hooks/useSetIntents";

import { Presenter } from "./Presenter";

export const PaymentMethod: React.FC<unknown> = () => {
  const { clientSecret } = useSetIntents();
  const { navigateBack } = useNavigates();

  return <Presenter goBack={navigateBack} clientSecret={clientSecret} />;
};
