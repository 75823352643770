import React, { forwardRef } from "react";

import {
  FormControl,
  Typography,
  Stack,
  Box,
  MenuItem,
  OutlinedInput,
  Select as MuiSelect,
  SelectChangeEvent,
} from "@mui/material";

import { Message } from "../../atoms/Message/index";

export type Props = {
  label: string;
  type: string;
  placeholder: string;
  required: boolean;
  options: {
    label: string;
    value: string;
  }[];
  value?: string;
  defaultValue?: string;
  error: boolean;
  disabled?: boolean;
  errorText?: string;
  onChange?: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
  sx?: any
};

export const Select = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      options,
      value,
      defaultValue,
      required,
      error,
      errorText,
      onChange,
      disabled,
      sx,
      ...field
    },
    ref
  ) => {
    return (
      <FormControl variant="filled" sx={{width: '100%'}}>
        <Stack spacing={1}>
          <Stack direction="row" spacing={0.5}>
            <Typography variant="bold14" sx={{ fontSize: 14 }}>
              {label}
            </Typography>
            {required && <Box color="alert.100">*</Box>}
          </Stack>
          <MuiSelect
            {...field}
            ref={ref}
            value={value}
            defaultValue={defaultValue || ""}
            onChange={onChange}
            disabled={disabled}
            input={
              <OutlinedInput
                inputProps={{
                  style: { padding: "13px 16px" },
                }}
              />
            }
            MenuProps={{
              sx: {
                "& .MuiList-root": {
                  maxHeight: "30vh",
                },
              },
            }}
            sx={sx}
          >
            {options.map(({ value, label }) => {
              return (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              );
            })}
          </MuiSelect>
          {error && errorText && <Message message={errorText} />}
        </Stack>
      </FormControl>
    );
  }
);
