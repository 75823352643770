import React from "react";

import { Backdrop, Box, CircularProgress, styled } from "@mui/material";
import { theme } from "providers/ThemeProviderWrap";

export type Props = {
  isLoading: boolean;
};

export const Loading: React.FC<Props> = (props) => {
  const { isLoading } = props;

  return (
    <Backdrop
      sx={{
        color: "func.100",
        backgroundColor: "#F4F7FC",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isLoading}
    >
      <CircularProgress sx={{color: theme.palette.primary.main}} />
    </Backdrop>
  );
};

export const StyledLoading = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
