/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * inojin-stg-api
 * OpenAPI spec version: 1.0.0
 */

/**
 * クライアント側で遷移先を判別するための値
0: サインアップ後のユーザー登録ページ
1: サインイン後のトップページ
 */
export type SocialLoginCallbackResponseResponseDataDestination = typeof SocialLoginCallbackResponseResponseDataDestination[keyof typeof SocialLoginCallbackResponseResponseDataDestination];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocialLoginCallbackResponseResponseDataDestination = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;
