import { useState, useCallback, useEffect } from "react";

import { usePostPatientSetupIntentsHook } from "api-client";
import { toast } from "react-toastify";

export const useSetIntents = () => {
  const [clientSecret, setClientSecret] = useState<undefined | string>();
  const postSetupIntents = useCallback(async () => {
    const patientSetupIntents = usePostPatientSetupIntentsHook();
    try {
      const res = await patientSetupIntents();
      setClientSecret(res.clientSecret);
    } catch (e: any) {
      toast.error(e.response.data.message);
      return;
    }
  }, []);

  useEffect(() => {
    postSetupIntents();
  }, [postSetupIntents]);

  return {
    clientSecret,
  };
};
