import * as React from "react";
import { useState } from "react";

import TextField, { TextFieldProps } from "@mui/material/TextField";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { ErrorTypography } from "components/atoms/Typography";
import { useTranslation } from "react-i18next";

import StripeInput from "./StripeInput";

export enum StripeFieldType {
  CardNo = 'cardNo',
  Expiry = 'expiry',
  SecurityCode = 'securityCode',
}

type StripeElement =
  | typeof CardCvcElement
  | typeof CardExpiryElement
  | typeof CardNumberElement;

interface StripeTextFieldProps<T extends StripeElement>
  extends Omit<TextFieldProps, "onChange" | "inputComponent" | "inputProps"> {
  inputProps?: React.ComponentProps<T>;
  labelErrorMessage?: string;
  onChange?: React.ComponentProps<T>["onChange"];
  stripeElement?: T;
  type?: StripeFieldType;
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StripeTextField = <T extends StripeElement>(
  props: StripeTextFieldProps<T>
) => {
  const {
    //helperText,
    InputLabelProps,
    InputProps = {},
    inputProps,
    //type,
    //error,
    stripeElement,
    setIsEdit,
    ...other
  } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [localError, setLocalError] = useState(false);
  const handleCardNumberChange = (event: any) => {
    setIsEdit && setIsEdit(true);
    //if(type === StripeFieldType.CardNo) {
    if (event?.error) {
      setLocalError(event.complete ? false : event.brand ? true : true);
      setErrorMessage(event.error?.message);
    } else {
      setLocalError(false);
    }
    //}
  };

  return (
    <>
      <TextField
        fullWidth
        InputLabelProps={{
          ...InputLabelProps,
          shrink: true,
        }}
        error={Boolean(localError)}
        InputProps={{
          ...InputProps,
          inputProps: {
            ...inputProps,
            ...InputProps.inputProps,
            component: stripeElement,
          },
          inputComponent: StripeInput,
        }}
        onChange={handleCardNumberChange}
        helperText={localError ?
          <ErrorTypography errorText={errorMessage} /> : ''}
        FormHelperTextProps={{
          sx: {
            margin: '0', // 他の必要なスタイルも追加できます
          },
        }}
        {...(other as any)}
      />
    </>
  );
};

export function StripeTextFieldNumber(
  props: StripeTextFieldProps<typeof CardNumberElement>
) {
  const { t } = useTranslation();

  return (
    <StripeTextField
      label={t("payment.cardNumber")}
      stripeElement={CardNumberElement}
      {...props}
    />
  );
}

export function StripeTextFieldExpiry(
  props: StripeTextFieldProps<typeof CardExpiryElement>
) {
  const { t } = useTranslation();

  return (
    <StripeTextField
      label={t("payment.expirationDate")}
      stripeElement={CardExpiryElement}
      {...props}
    />
  );
}

export function StripeTextFieldCVC(
  props: StripeTextFieldProps<typeof CardCvcElement>
) {
  const { t } = useTranslation();

  return (
    <StripeTextField label={t("payment.cvc")} stripeElement={CardCvcElement} {...props} />
  );
}
