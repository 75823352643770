import { useEffect } from 'react';

import { Room } from 'twilio-video';

export default function useHandleTrackPublicationFailed(room: Room | null) {
  const error = () => {
    console.error("error")
  }

  useEffect(() => {
    if (room) {
      room.localParticipant.on('trackPublicationFailed', error);
      return () => {
        room.localParticipant.off('trackPublicationFailed', error);
      };
    }
  }, [room]);
}
