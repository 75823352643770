import React from "react";

import { createTheme, ThemeProvider } from "@mui/material";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    bold20: React.CSSProperties;
    bold18: React.CSSProperties;
    bold16: React.CSSProperties;
    bold15: React.CSSProperties;
    bold14: React.CSSProperties;
    bold13: React.CSSProperties;
    bold12: React.CSSProperties;
    regular16: React.CSSProperties;
    regular15: React.CSSProperties;
    regular14: React.CSSProperties;
    regular13: React.CSSProperties;
    regular12: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bold20: React.CSSProperties;
    bold18: React.CSSProperties;
    bold16: React.CSSProperties;
    bold15: React.CSSProperties;
    bold14: React.CSSProperties;
    bold13: React.CSSProperties;
    bold12: React.CSSProperties;
    regular16: React.CSSProperties;
    regular15: React.CSSProperties;
    regular14: React.CSSProperties;
    regular13: React.CSSProperties;
    regular12: React.CSSProperties;
  }

  interface Palette {
    func: Palette;
    dark: Palette;
    orange: Palette;
    alert: Palette;
    link: Palette;
  }

  interface PaletteOptions {
    func?: PaletteOptions;
    dark?: PaletteOptions;
    orange?: PaletteOptions;
    alert?: PaletteOptions;
    link?: PaletteOptions;
  }

  interface SimplePaletteColorOptions {
    110?: string;
    100?: string;
    80?: string;
    70?: string;
    50?: string;
    40?: string;
    30?: string;
    20?: string;
    10?: string;
    5?: string;
    0?: string;
  }

  interface PaletteOptions {
    110?: string;
    100?: string;
    80?: string;
    70?: string;
    50?: string;
    40?: string;
    30?: string;
    20?: string;
    10?: string;
    5?: string;
    0?: string;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    bold20: true;
    bold18: true;
    bold16: true;
    bold15: true;
    bold14: true;
    bold13: true;
    bold12: true;
    regular16: true;
    regular15: true;
    regular14: true;
    regular13: true;
    regular12: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    onboarding: true;
    fixed: true;
  }
}

export const theme = createTheme({
  palette: {
    func: {
      110: "#2448BA",
      100: "#355CD7",
      70: "#728DE3",
      50: "#AEBEEF",
      30: "#C2CEF3",
      20: "#D7DEF7",
      10: "#EBEFFB",
      5: "#F5F7FD",
    },
    dark: {
      100: "#363A48",
      80: "#6F6F70",
      70: "#909090",
      50: "#C5C5C5",
      40: "#DDDDE0",
      20: "#EEEEEE",
      10: "#F7F8FB",
      0: "#FFFFFF",
    },
    orange: {
      110: "#F97C08",
      100: "#FFAE00",
      10: "#FFF7E6",
    },
    alert: {
      110: "#9A1314",
      100: "#E31717",
      50: "#FFF2F3",
    },
    link: {
      100: "#005ECD",
    },
  },
  typography: {
    bold20: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: "29px",
    },
    bold18: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: "29px",
    },
    bold16: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: "24px",
    },
    bold15: {
      fontSize: 15,
      fontWeight: 700,
      lineHeight: "22.5px",
    },
    bold14: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: "19.6px",
    },
    bold13: {
      fontSize: 13,
      fontWeight: 700,
      lineHeight: "18.2px",
    },
    bold12: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: "16.8px",
    },
    regular16: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "24px",
    },
    regular15: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: "22.5px",
    },
    regular14: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "19.6px",
    },
    regular13: {
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "140%",
    },
    regular12: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16.8px",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // currently, you need to provide all of the default mapping because it will replace, not merge.
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          // You have to add this line
          // otherwise the `span` (display `inline` by default)
          // cannot have margin.
          bold20: "p",
          bold18: "p",
          bold16: "p",
          bold15: "p",
          bold14: "p",
          bold13: "p",
          bold12: "p",
          regular16: "p",
          regular15: "p",
          regular14: "p",
          regular13: "p",
          regular12: "p",
        },
      },
      styleOverrides: {
        h1: {
          fontSize: 21,
          fontWeight: 700,
          lineHeight: "27.3px",
        },
        h2: {
          fontSize: 20,
          fontWeight: 700,
          lineHeight: "26px",
        },
        h3: {
          fontSize: 18,
          fontWeight: 700,
          lineHeight: "23.4px",
        },
        h4: {
          fontSize: 16,
          fontWeight: 700,
          lineHeight: "20.8px",
        },
        h5: {
          fontSize: 14,
          fontWeight: 700,
          lineHeight: "18.2px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          lineHeight: "16px",
          fontSize: "16px",
          ...(ownerState.variant === "onboarding" &&
            ownerState.color === "primary" && {
              backgroundColor: "#355CD7",
              color: "#fff",
              borderRadius: "10px",
              padding: "13px 24px",
              fontWeight: 700,
              "&:disabled": {
                backgroundColor: "#EBEFFB",
                color: "#fff",
              },
              "&:hover": {
                backgroundColor: "#EBEFFB",
                color: "#fff",
              },
            }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              backgroundColor: "#355CD7",
              color: "#fff",
              borderRadius: "46px",
              padding: "16px 54px",
              fontWeight: 500,
              "&:disabled": {
                backgroundColor: "#EBEFFB",
                color: "#fff",
              },
            }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "error" && {
              backgroundColor: "#E31717",
              color: "#fff",
              borderRadius: "46px",
              padding: "16px 54px",
              fontWeight: 700,
            }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "secondary" && {
              backgroundColor: "#EBEFFB",
              color: "#355CD7",
              borderRadius: "46px",
              padding: "16px 54px",
              fontWeight: 500,
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#EBEFFB",
                color: "#355CD7",
                boxShadow: "none",
              },
            }),
          ...(ownerState.variant === "outlined" &&
            ownerState.color === "primary" && {
              backgroundColor: "#EBEFFB",
              color: "#355CD7",
              borderRadius: "46px",
              padding: "16px 54px",
              fontWeight: 500,
            }),
          ...(ownerState.variant === "outlined" &&
            ownerState.color === "primary" &&
            ownerState.size === "medium" && {
              backgroundColor: "#fff",
              color: "#355CD7",
              borderRadius: "46px",
              padding: "12px 54px",
              fontWeight: 500,
            }),
          ...(ownerState.variant === "outlined" &&
            ownerState.color === "primary" &&
            ownerState.size === "small" && {
              backgroundColor: "#fff",
              color: "#355CD7",
              borderRadius: "24px",
              padding: "12px 16px",
              fontWeight: 400,
              fontSize: "12px",
              width: "sm",
            }),
          ...(ownerState.variant === "fixed" &&
            ownerState.color === "primary" && {
              border: "1px solid #355CD7",
              backgroundColor: "#fff",
              color: "#355CD7",
              borderRadius: "8px",
              padding: "12px 10px",
              fontWeight: 400,
              width: "sm",
            }),
        }),
      },
      variants: [
        {
          props: { variant: "onboarding" },
          style: {},
        },
        {
          props: { variant: "fixed" },
          style: {},
        },
      ],
    },
  },
});

type ThemeProviderWrapProps = React.PropsWithChildren<{}>;

export const ThemeProviderWrap: React.FC<ThemeProviderWrapProps> = ({
  children,
}) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;
