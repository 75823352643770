import { useEffect, useCallback } from "react";

import { LoginBodiesAuthFlow, useLoginHook, useVerifyHook } from "api-client";
import { decodeToken } from "react-jwt";
import { useNavigate } from "react-router-dom";

export const Session = () => {
  const navigate = useNavigate();
  const idToken = localStorage.getItem("idToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const decodedIdToken = idToken ? decodeToken<any>(idToken) : '';

  const extentionRefreshToken = async () => {
    try {
      const login = useLoginHook();

      const result = await login({
        refreshToken: refreshToken!,
        authFlow: LoginBodiesAuthFlow.REFRESH_TOKEN_AUTH,
      });
      console.log(result)
      if (!result?.data?.idToken && !result?.data?.accessToken && !result?.data?.refreshToken) {
        localStorage.clear();
        const navigate = useNavigate();
        navigate("/login");
        return;
      }

      if (result?.data?.idToken) {
        //updateIdToken(result.data.idToken);
        localStorage.idToken = result.data.idToken;
      }
      if (result?.data?.accessToken) {
        //updateAccessToken(result.data.accessToken);
        localStorage.accessToken = result.data.accessToken;
      }
      if (result?.data?.refreshToken) {
        //updateRefreshToken(result.data.refreshToken);
        localStorage.refreshToken = result.data.refreshToken;
      }
    } catch (e) {
      localStorage.clear();
      const navigate = useNavigate();
      navigate("/login");
    }
  };

  const observeAuth = useCallback(async () => {
    if (!idToken) {
      localStorage.clear();
      return;
    }

    try {
      const verify = useVerifyHook();
      await verify();

      if (refreshToken) {
        // トークンの有効期限 (exp) を秒単位で取得
        const expirationTimestamp = decodedIdToken?.exp!; // トークンの有効期限 (exp) のタイムスタンプ
        // 現在の時間 (Unix エポックからの経過秒数)
        const currentTimestamp = Math.floor(Date.now() / 1000);
        // トークンの有効期限までの残り秒数を計算
        const remainingSeconds = expirationTimestamp - currentTimestamp;
        // 残り秒数を分単位に変換
        const remainingIdTokenMinutes = Math.floor(remainingSeconds / 60);

        console.debug('Rmaining Session minutes: ' + remainingIdTokenMinutes); // 有効期限までの残り分数

        if (remainingIdTokenMinutes <= 5) {
          extentionRefreshToken();
          return;
        }
      }
    } catch (e: any) {
      // JWTが有効期限切れ
      localStorage.clear();
      navigate("/login");
      return;

      /* const refreshToken = localStorage.getItem("refreshToken");
 
       if (!refreshToken) {
         localStorage.clear();
         return;
       }
 
       extentionRefreshToken();
       return;*/

      /*try {
        const login = useLoginHook();
        const result = await login({
          refreshToken: refreshToken,
          authFlow: LoginBodiesAuthFlow.REFRESH_TOKEN_AUTH,
        });

        if (
          result?.data?.idToken &&
          result?.data?.accessToken &&
          result?.data?.refreshToken
        ) {
          localStorage.idToken = result.data.idToken;
          localStorage.accessToken = result.data.accessToken;
          localStorage.refreshToken = result.data.refreshToken;
        }
      } catch (e) {
        localStorage.clear();
        navigate("/login");
      }
      return;*/
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    observeAuth();
    // eslint-disable-next-line
  }, [observeAuth]);

  return <>{null}</>;
};
