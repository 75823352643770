import { PatientModelDTO } from "api-client";
import { atom } from "recoil";

export const userState = atom<PatientModelDTO>({
  key: "userState",
  default: {
    id: "",
    name: "",
    nameKana: "",
    email: "",
    postalCode: "",
    prefecture: "",
    address: "",
    phoneNumber: "",
    gender: "MALE",
    lang: "JP",
    insuranceCardUpdatedAt: "",
    paymentMethods: [],
    finishedInitRegister: false,
    additionalDocuments: []
  },
});
