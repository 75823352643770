import React, { useEffect, useState } from "react";

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { PatientModelDTO, useGetPatientHook, usePatchPatient, usePostPatientPaymentMethod, usePostShippingAddressesHook } from "api-client";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useNavigates } from "hooks/useNavigates";
import { useSessionStorage } from "hooks/useSessionStorage";
import { Id, toast } from "react-toastify";
//import { useRecoilValue } from "recoil";
//import { me } from "selectors/user";

import { Presenter } from "./Presenter";

type Props = {
  clientSecret: string;
};

export const CheckoutForm: React.FC<Props> = (props) => {
  const { clientSecret } = props;
  const [value] = useLocalStorage("patientId");
  const [userData] = useSessionStorage("user");
  const { navigateSignUpFinish } = useNavigates();
  const [disabled, setDisabled] = useState(true);
  const [isCardNumberElementCompleted, setIsCardNumberElementCompleted] = useState(false);
  const [isCardExpiryElementCompleted, setIsCardExpiryElementCompleted] = useState(false);
  const [isCardCvcElementCompleted, setIsCardCvcElementCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [patient, setPaient] = useState<PatientModelDTO>();
  //const patient = useRecoilValue(me);
  const [patientId] = useLocalStorage("patientId");
  const stripe = useStripe();
  const elements = useElements();
  const usePostShippingAddresses = usePostShippingAddressesHook();

  useEffect(() => {
    if (!elements) return setLoading(true);
    const cardNumberElement = elements.getElement(CardNumberElement)
    const cardExpiryElement = elements.getElement(CardExpiryElement)
    const cardCvcElement = elements.getElement(CardCvcElement)

    // NOTE: カード番号の入力完了を判定
    if(cardNumberElement) {
      cardNumberElement.on('change', (event) => {
        console.log("###cardNumberElement", event.complete)
        setIsCardNumberElementCompleted(event.complete);
      });
    } else {
      setIsCardNumberElementCompleted(false);
    }

    // NOTE: 期限の入力完了を判定
    if(cardExpiryElement) {
      cardExpiryElement.on('change', (event) => {
        console.log("###cardExpiryElement", event.complete)
        setIsCardExpiryElementCompleted(event.complete);
      });
    } else {
      setIsCardExpiryElementCompleted(false);
    }

    // NOTE: CVCの入力完了を判定
    if(cardCvcElement) {
      cardCvcElement.on('change', (event) => {
        console.log("###cardCvcElement", event.complete)
        setIsCardCvcElementCompleted(event.complete);
      });
    } else {
      setIsCardCvcElementCompleted(false);
    }
  }, [elements])

  useEffect(() => {
    if(isCardNumberElementCompleted && isCardExpiryElementCompleted && isCardCvcElementCompleted) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [isCardCvcElementCompleted, isCardExpiryElementCompleted, isCardNumberElementCompleted])

  /*const refreshUserInfo = useRecoilCallback(
    ({ set }) =>
      async () => {
        const patient = useGetPatientHook();
        const me = patient();
        set(userState, await me);
      },
    [me]
  );*/
  const getPatient = async () => {
    const getPatient = await useGetPatientHook();
    const me = getPatient();
    const patient = await me;

    setPaient(patient);
  };

  useEffect(() => {
    getPatient();
    // eslint-disable-next-line
  }, []);

  const postPatientPaymentMethod = usePostPatientPaymentMethod({
    mutation: {
      onSuccess() {
        navigateSignUpFinish();
      },
      onError(e) {
        const toastId = toast.error(`${e.message}`);
        setTimeout(() => {
          toast.dismiss(toastId);
        }, 2000);
        setDisabled(true);
        setLoading(false);
      },
    },
  });
  const patchPatient = usePatchPatient({
    mutation: {
      onSuccess(_) {
        let toastId:Id = ""
        if (patient?.paymentMethods?.length === 0) {
          toastId = toast.success("カードの登録が完了しました");
        } else {
          toastId = toast.success("カードの更新が完了しました");
        }
        setTimeout(() => {
          toast.dismiss(toastId);
        }, 2000);
      },
      onError(e) {
        toast.error(`${e}`);
      },
    },
  });

  const handleSubmit = async (event: any) => {
    if (!stripe || !elements) return;
    setDisabled(true);
    setLoading(true);

    event.preventDefault();
    const cardNumberElement = elements.getElement(CardNumberElement);

    if (!cardNumberElement) return;

    try {
      const { error, setupIntent } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: cardNumberElement,
            billing_details: {},
          },
        }
      );
      if (error) {
        console.error(error)
        const toastId = toast.error(`${error.message}`);
        setTimeout(() => {
          toast.dismiss(toastId);
          setDisabled(false);
          setLoading(false);
        }, 2000);
        return;
      }

      // 入力された住所をお薬のお届け先住所のデフォルトとして登録する
      const today = new Date();
      const usePostShippingAddressesResult = await usePostShippingAddresses({
        patientId: patientId!,
        prefecture: patient!.prefecture,
        postalCode: patient!.postalCode,
        address: patient!.address,
        createdAt: today.getTime(),
        updatedAt: today.getTime(),
        createdBy: patientId!,
        updatedBy: patientId!,
        isDeleted: 0
      });

      // 初回登録完了フラグをtrueにする
      await patchPatient.mutate({
        id: patientId!,
        data: {
          name: patient!.name,
          nameKana: patient!.nameKana,
          email: patient!.email,
          postalCode: patient!.postalCode,
          prefecture: patient!.prefecture,
          address: patient!.address,
          gender: patient!.gender,
          birthDay: patient!.birthDay || "",
          phoneNumber: patient!.phoneNumber,
          finishedInitRegister: true,
          shippingAddressId: usePostShippingAddressesResult.id
        },
      });
      // ユーザ情報更新
      //await refreshUserInfo();
      localStorage.setItem("finishedInitRegister", "true");

      if (setupIntent) {
        postPatientPaymentMethod.mutate({
          data: {
            id: `${value}`,
            name: userData?.name || "",
            email: userData?.email || "",
            paymentMethod: `${setupIntent.payment_method}`,
          },
        });
      }
    } catch (e) {
      console.error("Error confirming card setup:", e);
    }
    setDisabled(false);
    setLoading(false);
  };

  return (
    <Presenter
      handleSubmit={handleSubmit}
      stripe={stripe}
      disabled={disabled}
      loading={loading}
    />
  );
};
