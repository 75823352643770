import React from "react";

import { Paper, Stack, Typography } from "@mui/material";
import { Logo } from "components/atoms/Logo";
import { QrCode } from "components/atoms/QrCode";

export const PCDisplay: React.FC = () => {
  const textStyle = {
    fontWeight: 700,
    fontSize: "18px",
  };

  return (
    <div
      style={{
        background: "#F5F7FD",
        width: "100vw",
        height: "100vh",
        alignContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
      }}
    >
      <Paper
        sx={{
          borderRadius: "16px",
          padding: "78px 128px 168px 128px",
          boxShadow: "12px 26px 50px 0px #5A6CEA14",
          width: "700px",
          mx: "calc(50vw - 350px)",
          boxSizing: "border-box",
        }}
      >
        <Stack
          spacing={5}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Logo />
          <Typography style={textStyle}>
            {
              "お使いのデバイスではてのひら眼科のオンライン診療\nシステムをご利用いただけません。"
            }
          </Typography>
        </Stack>
        <Stack
          spacing={6}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          pt={4}
        >
          <QrCode />
          <Stack>
            <Typography style={{ ...textStyle, background: "#FFE799" }}>
              スマートフォンでQRコードを読み取っていただき、
            </Typography>
            <Typography style={textStyle}>ご利用をお願いいたします</Typography>
          </Stack>
        </Stack>
      </Paper>
    </div>
  );
};
