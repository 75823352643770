export const QrCode = () => {
  return (
    <svg
      width="210"
      height="210"
      viewBox="0 0 370 370"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_184_1235)">
        <path d="M70 0H0V10H70V0Z" fill="black" />
        <path d="M130 0H110V10H130V0Z" fill="black" />
        <path d="M150 0H140V10H150V0Z" fill="black" />
        <path d="M190 0H160V10H190V0Z" fill="black" />
        <path d="M210 0H200V10H210V0Z" fill="black" />
        <path d="M230 0H220V10H230V0Z" fill="black" />
        <path d="M290 0H250V10H290V0Z" fill="black" />
        <path d="M370 0H300V10H370V0Z" fill="black" />
        <path d="M10 10H0V20H10V10Z" fill="black" />
        <path d="M70 10H60V20H70V10Z" fill="black" />
        <path d="M130 10H80V20H130V10Z" fill="black" />
        <path d="M190 10H170V20H190V10Z" fill="black" />
        <path d="M210 10H200V20H210V10Z" fill="black" />
        <path d="M250 10H240V20H250V10Z" fill="black" />
        <path d="M290 10H280V20H290V10Z" fill="black" />
        <path d="M310 10H300V20H310V10Z" fill="black" />
        <path d="M370 10H360V20H370V10Z" fill="black" />
        <path d="M10 20H0V30H10V20Z" fill="black" />
        <path d="M50 20H20V30H50V20Z" fill="black" />
        <path d="M70 20H60V30H70V20Z" fill="black" />
        <path d="M140 20H90V30H140V20Z" fill="black" />
        <path d="M170 20H150V30H170V20Z" fill="black" />
        <path d="M210 20H180V30H210V20Z" fill="black" />
        <path d="M240 20H220V30H240V20Z" fill="black" />
        <path d="M290 20H260V30H290V20Z" fill="black" />
        <path d="M310 20H300V30H310V20Z" fill="black" />
        <path d="M350 20H320V30H350V20Z" fill="black" />
        <path d="M370 20H360V30H370V20Z" fill="black" />
        <path d="M10 30H0V40H10V30Z" fill="black" />
        <path d="M50 30H20V40H50V30Z" fill="black" />
        <path d="M70 30H60V40H70V30Z" fill="black" />
        <path d="M100 30H90V40H100V30Z" fill="black" />
        <path d="M120 30H110V40H120V30Z" fill="black" />
        <path d="M150 30H140V40H150V30Z" fill="black" />
        <path d="M170 30H160V40H170V30Z" fill="black" />
        <path d="M200 30H180V40H200V30Z" fill="black" />
        <path d="M230 30H220V40H230V30Z" fill="black" />
        <path d="M290 30H250V40H290V30Z" fill="black" />
        <path d="M310 30H300V40H310V30Z" fill="black" />
        <path d="M350 30H320V40H350V30Z" fill="black" />
        <path d="M370 30H360V40H370V30Z" fill="black" />
        <path d="M10 40H0V50H10V40Z" fill="black" />
        <path d="M50 40H20V50H50V40Z" fill="black" />
        <path d="M70 40H60V50H70V40Z" fill="black" />
        <path d="M130 40H90V50H130V40Z" fill="black" />
        <path d="M160 40H150V50H160V40Z" fill="black" />
        <path d="M180 40H170V50H180V40Z" fill="black" />
        <path d="M250 40H200V50H250V40Z" fill="black" />
        <path d="M280 40H260V50H280V40Z" fill="black" />
        <path d="M310 40H300V50H310V40Z" fill="black" />
        <path d="M350 40H320V50H350V40Z" fill="black" />
        <path d="M370 40H360V50H370V40Z" fill="black" />
        <path d="M10 50H0V60H10V50Z" fill="black" />
        <path d="M70 50H60V60H70V50Z" fill="black" />
        <path d="M100 50H80V60H100V50Z" fill="black" />
        <path d="M120 50H110V60H120V50Z" fill="black" />
        <path d="M160 50H150V60H160V50Z" fill="black" />
        <path d="M180 50H170V60H180V50Z" fill="black" />
        <path d="M220 50H210V60H220V50Z" fill="black" />
        <path d="M250 50H230V60H250V50Z" fill="black" />
        <path d="M290 50H280V60H290V50Z" fill="black" />
        <path d="M310 50H300V60H310V50Z" fill="black" />
        <path d="M370 50H360V60H370V50Z" fill="black" />
        <path d="M70 60H0V70H70V60Z" fill="black" />
        <path d="M90 60H80V70H90V60Z" fill="black" />
        <path d="M110 60H100V70H110V60Z" fill="black" />
        <path d="M130 60H120V70H130V60Z" fill="black" />
        <path d="M150 60H140V70H150V60Z" fill="black" />
        <path d="M170 60H160V70H170V60Z" fill="black" />
        <path d="M190 60H180V70H190V60Z" fill="black" />
        <path d="M210 60H200V70H210V60Z" fill="black" />
        <path d="M230 60H220V70H230V60Z" fill="black" />
        <path d="M250 60H240V70H250V60Z" fill="black" />
        <path d="M270 60H260V70H270V60Z" fill="black" />
        <path d="M290 60H280V70H290V60Z" fill="black" />
        <path d="M370 60H300V70H370V60Z" fill="black" />
        <path d="M90 70H80V80H90V70Z" fill="black" />
        <path d="M150 70H100V80H150V70Z" fill="black" />
        <path d="M180 70H160V80H180V70Z" fill="black" />
        <path d="M210 70H190V80H210V70Z" fill="black" />
        <path d="M260 70H240V80H260V70Z" fill="black" />
        <path d="M80 80H40V90H80V80Z" fill="black" />
        <path d="M140 80H130V90H140V80Z" fill="black" />
        <path d="M160 80H150V90H160V80Z" fill="black" />
        <path d="M230 80H170V90H230V80Z" fill="black" />
        <path d="M260 80H240V90H260V80Z" fill="black" />
        <path d="M290 80H270V90H290V80Z" fill="black" />
        <path d="M320 80H300V90H320V80Z" fill="black" />
        <path d="M360 80H350V90H360V80Z" fill="black" />
        <path d="M30 90H10V100H30V90Z" fill="black" />
        <path d="M60 90H40V100H60V90Z" fill="black" />
        <path d="M90 90H80V100H90V90Z" fill="black" />
        <path d="M140 90H130V100H140V90Z" fill="black" />
        <path d="M190 90H170V100H190V90Z" fill="black" />
        <path d="M220 90H200V100H220V90Z" fill="black" />
        <path d="M250 90H240V100H250V90Z" fill="black" />
        <path d="M270 90H260V100H270V90Z" fill="black" />
        <path d="M350 90H310V100H350V90Z" fill="black" />
        <path d="M20 100H0V110H20V100Z" fill="black" />
        <path d="M50 100H40V110H50V100Z" fill="black" />
        <path d="M80 100H60V110H80V100Z" fill="black" />
        <path d="M130 100H110V110H130V100Z" fill="black" />
        <path d="M170 100H140V110H170V100Z" fill="black" />
        <path d="M190 100H180V110H190V100Z" fill="black" />
        <path d="M280 100H200V110H280V100Z" fill="black" />
        <path d="M300 100H290V110H300V100Z" fill="black" />
        <path d="M320 100H310V110H320V100Z" fill="black" />
        <path d="M10 110H0V120H10V110Z" fill="black" />
        <path d="M40 110H30V120H40V110Z" fill="black" />
        <path d="M60 110H50V120H60V110Z" fill="black" />
        <path d="M160 110H90V120H160V110Z" fill="black" />
        <path d="M200 110H190V120H200V110Z" fill="black" />
        <path d="M220 110H210V120H220V110Z" fill="black" />
        <path d="M300 110H260V120H300V110Z" fill="black" />
        <path d="M330 110H310V120H330V110Z" fill="black" />
        <path d="M370 110H340V120H370V110Z" fill="black" />
        <path d="M10 120H0V130H10V120Z" fill="black" />
        <path d="M50 120H30V130H50V120Z" fill="black" />
        <path d="M70 120H60V130H70V120Z" fill="black" />
        <path d="M100 120H90V130H100V120Z" fill="black" />
        <path d="M130 120H120V130H130V120Z" fill="black" />
        <path d="M150 120H140V130H150V120Z" fill="black" />
        <path d="M190 120H180V130H190V120Z" fill="black" />
        <path d="M240 120H200V130H240V120Z" fill="black" />
        <path d="M320 120H290V130H320V120Z" fill="black" />
        <path d="M360 120H340V130H360V120Z" fill="black" />
        <path d="M10 130H0V140H10V130Z" fill="black" />
        <path d="M60 130H40V140H60V130Z" fill="black" />
        <path d="M80 130H70V140H80V130Z" fill="black" />
        <path d="M110 130H90V140H110V130Z" fill="black" />
        <path d="M150 130H140V140H150V130Z" fill="black" />
        <path d="M190 130H160V140H190V130Z" fill="black" />
        <path d="M220 130H200V140H220V130Z" fill="black" />
        <path d="M250 130H230V140H250V130Z" fill="black" />
        <path d="M300 130H270V140H300V130Z" fill="black" />
        <path d="M330 130H310V140H330V130Z" fill="black" />
        <path d="M350 130H340V140H350V130Z" fill="black" />
        <path d="M20 140H10V150H20V140Z" fill="black" />
        <path d="M40 140H30V150H40V140Z" fill="black" />
        <path d="M90 140H50V150H90V140Z" fill="black" />
        <path d="M120 140H100V150H120V140Z" fill="black" />
        <path d="M140 140H130V150H140V140Z" fill="black" />
        <path d="M170 140H150V150H170V140Z" fill="black" />
        <path d="M200 140H190V150H200V140Z" fill="black" />
        <path d="M230 140H220V150H230V140Z" fill="black" />
        <path d="M280 140H250V150H280V140Z" fill="black" />
        <path d="M300 140H290V150H300V140Z" fill="black" />
        <path d="M350 140H330V150H350V140Z" fill="black" />
        <path d="M40 150H0V160H40V150Z" fill="black" />
        <path d="M60 150H50V160H60V150Z" fill="black" />
        <path d="M100 150H70V160H100V150Z" fill="black" />
        <path d="M150 150H120V160H150V150Z" fill="black" />
        <path d="M180 150H160V160H180V150Z" fill="black" />
        <path d="M220 150H200V160H220V150Z" fill="black" />
        <path d="M320 150H290V160H320V150Z" fill="black" />
        <path d="M370 150H340V160H370V150Z" fill="black" />
        <path d="M80 160H40V170H80V160Z" fill="black" />
        <path d="M120 160H110V170H120V160Z" fill="black" />
        <path d="M180 160H140V170H180V160Z" fill="black" />
        <path d="M250 160H200V170H250V160Z" fill="black" />
        <path d="M280 160H270V170H280V160Z" fill="black" />
        <path d="M310 160H290V170H310V160Z" fill="black" />
        <path d="M360 160H330V170H360V160Z" fill="black" />
        <path d="M10 170H0V180H10V170Z" fill="black" />
        <path d="M40 170H30V180H40V170Z" fill="black" />
        <path d="M110 170H70V180H110V170Z" fill="black" />
        <path d="M130 170H120V180H130V170Z" fill="black" />
        <path d="M180 170H150V180H180V170Z" fill="black" />
        <path d="M210 170H190V180H210V170Z" fill="black" />
        <path d="M250 170H220V180H250V170Z" fill="black" />
        <path d="M290 170H260V180H290V170Z" fill="black" />
        <path d="M350 170H320V180H350V170Z" fill="black" />
        <path d="M20 180H0V190H20V180Z" fill="black" />
        <path d="M40 180H30V190H40V180Z" fill="black" />
        <path d="M70 180H60V190H70V180Z" fill="black" />
        <path d="M90 180H80V190H90V180Z" fill="black" />
        <path d="M130 180H100V190H130V180Z" fill="black" />
        <path d="M160 180H150V190H160V180Z" fill="black" />
        <path d="M220 180H210V190H220V180Z" fill="black" />
        <path d="M250 180H240V190H250V180Z" fill="black" />
        <path d="M280 180H270V190H280V180Z" fill="black" />
        <path d="M320 180H300V190H320V180Z" fill="black" />
        <path d="M10 190H0V200H10V190Z" fill="black" />
        <path d="M50 190H40V200H50V190Z" fill="black" />
        <path d="M80 190H70V200H80V190Z" fill="black" />
        <path d="M110 190H90V200H110V190Z" fill="black" />
        <path d="M130 190H120V200H130V190Z" fill="black" />
        <path d="M200 190H170V200H200V190Z" fill="black" />
        <path d="M220 190H210V200H220V190Z" fill="black" />
        <path d="M270 190H260V200H270V190Z" fill="black" />
        <path d="M310 190H300V200H310V190Z" fill="black" />
        <path d="M330 190H320V200H330V190Z" fill="black" />
        <path d="M370 190H340V200H370V190Z" fill="black" />
        <path d="M40 200H20V210H40V200Z" fill="black" />
        <path d="M70 200H60V210H70V200Z" fill="black" />
        <path d="M90 200H80V210H90V200Z" fill="black" />
        <path d="M110 200H100V210H110V200Z" fill="black" />
        <path d="M180 200H160V210H180V200Z" fill="black" />
        <path d="M220 200H190V210H220V200Z" fill="black" />
        <path d="M250 200H230V210H250V200Z" fill="black" />
        <path d="M310 200H290V210H310V200Z" fill="black" />
        <path d="M370 200H340V210H370V200Z" fill="black" />
        <path d="M30 210H10V220H30V210Z" fill="black" />
        <path d="M60 210H40V220H60V210Z" fill="black" />
        <path d="M120 210H100V220H120V210Z" fill="black" />
        <path d="M140 210H130V220H140V210Z" fill="black" />
        <path d="M190 210H170V220H190V210Z" fill="black" />
        <path d="M220 210H200V220H220V210Z" fill="black" />
        <path d="M240 210H230V220H240V210Z" fill="black" />
        <path d="M280 210H250V220H280V210Z" fill="black" />
        <path d="M350 210H320V220H350V210Z" fill="black" />
        <path d="M20 220H0V230H20V220Z" fill="black" />
        <path d="M80 220H60V230H80V220Z" fill="black" />
        <path d="M140 220H100V230H140V220Z" fill="black" />
        <path d="M170 220H150V230H170V220Z" fill="black" />
        <path d="M230 220H190V230H230V220Z" fill="black" />
        <path d="M250 220H240V230H250V220Z" fill="black" />
        <path d="M290 220H270V230H290V220Z" fill="black" />
        <path d="M310 220H300V230H310V220Z" fill="black" />
        <path d="M340 220H330V230H340V220Z" fill="black" />
        <path d="M10 230H0V240H10V230Z" fill="black" />
        <path d="M40 230H30V240H40V230Z" fill="black" />
        <path d="M60 230H50V240H60V230Z" fill="black" />
        <path d="M80 230H70V240H80V230Z" fill="black" />
        <path d="M130 230H100V240H130V230Z" fill="black" />
        <path d="M160 230H150V240H160V230Z" fill="black" />
        <path d="M240 230H220V240H240V230Z" fill="black" />
        <path d="M270 230H260V240H270V230Z" fill="black" />
        <path d="M290 230H280V240H290V230Z" fill="black" />
        <path d="M320 230H300V240H320V230Z" fill="black" />
        <path d="M360 230H340V240H360V230Z" fill="black" />
        <path d="M20 240H10V250H20V240Z" fill="black" />
        <path d="M40 240H30V250H40V240Z" fill="black" />
        <path d="M100 240H60V250H100V240Z" fill="black" />
        <path d="M120 240H110V250H120V240Z" fill="black" />
        <path d="M180 240H170V250H180V240Z" fill="black" />
        <path d="M210 240H200V250H210V240Z" fill="black" />
        <path d="M280 240H250V250H280V240Z" fill="black" />
        <path d="M310 240H300V250H310V240Z" fill="black" />
        <path d="M370 240H340V250H370V240Z" fill="black" />
        <path d="M10 250H0V260H10V250Z" fill="black" />
        <path d="M40 250H20V260H40V250Z" fill="black" />
        <path d="M160 250H90V260H160V250Z" fill="black" />
        <path d="M180 250H170V260H180V250Z" fill="black" />
        <path d="M200 250H190V260H200V250Z" fill="black" />
        <path d="M220 250H210V260H220V250Z" fill="black" />
        <path d="M260 250H240V260H260V250Z" fill="black" />
        <path d="M290 250H280V260H290V250Z" fill="black" />
        <path d="M350 250H300V260H350V250Z" fill="black" />
        <path d="M50 260H30V270H50V260Z" fill="black" />
        <path d="M80 260H60V270H80V260Z" fill="black" />
        <path d="M150 260H130V270H150V260Z" fill="black" />
        <path d="M170 260H160V270H170V260Z" fill="black" />
        <path d="M210 260H200V270H210V260Z" fill="black" />
        <path d="M250 260H230V270H250V260Z" fill="black" />
        <path d="M270 260H260V270H270V260Z" fill="black" />
        <path d="M300 260H280V270H300V260Z" fill="black" />
        <path d="M320 260H310V270H320V260Z" fill="black" />
        <path d="M340 260H330V270H340V260Z" fill="black" />
        <path d="M360 260H350V270H360V260Z" fill="black" />
        <path d="M60 270H20V280H60V270Z" fill="black" />
        <path d="M140 270H70V280H140V270Z" fill="black" />
        <path d="M180 270H170V280H180V270Z" fill="black" />
        <path d="M200 270H190V280H200V270Z" fill="black" />
        <path d="M240 270H210V280H240V270Z" fill="black" />
        <path d="M320 270H300V280H320V270Z" fill="black" />
        <path d="M350 270H340V280H350V270Z" fill="black" />
        <path d="M50 280H0V290H50V280Z" fill="black" />
        <path d="M70 280H60V290H70V280Z" fill="black" />
        <path d="M100 280H90V290H100V280Z" fill="black" />
        <path d="M140 280H130V290H140V280Z" fill="black" />
        <path d="M190 280H170V290H190V280Z" fill="black" />
        <path d="M210 280H200V290H210V280Z" fill="black" />
        <path d="M250 280H240V290H250V280Z" fill="black" />
        <path d="M330 280H270V290H330V280Z" fill="black" />
        <path d="M350 280H340V290H350V280Z" fill="black" />
        <path d="M370 280H360V290H370V280Z" fill="black" />
        <path d="M110 290H80V300H110V290Z" fill="black" />
        <path d="M140 290H130V300H140V290Z" fill="black" />
        <path d="M170 290H160V300H170V290Z" fill="black" />
        <path d="M240 290H210V300H240V290Z" fill="black" />
        <path d="M270 290H260V300H270V290Z" fill="black" />
        <path d="M290 290H280V300H290V290Z" fill="black" />
        <path d="M340 290H320V300H340V290Z" fill="black" />
        <path d="M70 300H0V310H70V300Z" fill="black" />
        <path d="M90 300H80V310H90V300Z" fill="black" />
        <path d="M120 300H110V310H120V300Z" fill="black" />
        <path d="M170 300H140V310H170V300Z" fill="black" />
        <path d="M260 300H200V310H260V300Z" fill="black" />
        <path d="M290 300H280V310H290V300Z" fill="black" />
        <path d="M310 300H300V310H310V300Z" fill="black" />
        <path d="M350 300H320V310H350V300Z" fill="black" />
        <path d="M10 310H0V320H10V310Z" fill="black" />
        <path d="M70 310H60V320H70V310Z" fill="black" />
        <path d="M90 310H80V320H90V310Z" fill="black" />
        <path d="M110 310H100V320H110V310Z" fill="black" />
        <path d="M200 310H170V320H200V310Z" fill="black" />
        <path d="M250 310H240V320H250V310Z" fill="black" />
        <path d="M270 310H260V320H270V310Z" fill="black" />
        <path d="M290 310H280V320H290V310Z" fill="black" />
        <path d="M330 310H320V320H330V310Z" fill="black" />
        <path d="M350 310H340V320H350V310Z" fill="black" />
        <path d="M10 320H0V330H10V320Z" fill="black" />
        <path d="M50 320H20V330H50V320Z" fill="black" />
        <path d="M70 320H60V330H70V320Z" fill="black" />
        <path d="M90 320H80V330H90V320Z" fill="black" />
        <path d="M110 320H100V330H110V320Z" fill="black" />
        <path d="M150 320H120V330H150V320Z" fill="black" />
        <path d="M180 320H160V330H180V320Z" fill="black" />
        <path d="M220 320H210V330H220V320Z" fill="black" />
        <path d="M250 320H230V330H250V320Z" fill="black" />
        <path d="M370 320H280V330H370V320Z" fill="black" />
        <path d="M10 330H0V340H10V330Z" fill="black" />
        <path d="M50 330H20V340H50V330Z" fill="black" />
        <path d="M70 330H60V340H70V330Z" fill="black" />
        <path d="M110 330H100V340H110V330Z" fill="black" />
        <path d="M180 330H130V340H180V330Z" fill="black" />
        <path d="M200 330H190V340H200V330Z" fill="black" />
        <path d="M220 330H210V340H220V330Z" fill="black" />
        <path d="M270 330H260V340H270V330Z" fill="black" />
        <path d="M310 330H300V340H310V330Z" fill="black" />
        <path d="M350 330H340V340H350V330Z" fill="black" />
        <path d="M10 340H0V350H10V340Z" fill="black" />
        <path d="M50 340H20V350H50V340Z" fill="black" />
        <path d="M70 340H60V350H70V340Z" fill="black" />
        <path d="M130 340H100V350H130V340Z" fill="black" />
        <path d="M150 340H140V350H150V340Z" fill="black" />
        <path d="M180 340H160V350H180V340Z" fill="black" />
        <path d="M270 340H250V350H270V340Z" fill="black" />
        <path d="M320 340H300V350H320V340Z" fill="black" />
        <path d="M360 340H330V350H360V340Z" fill="black" />
        <path d="M10 350H0V360H10V350Z" fill="black" />
        <path d="M70 350H60V360H70V350Z" fill="black" />
        <path d="M110 350H90V360H110V350Z" fill="black" />
        <path d="M140 350H120V360H140V350Z" fill="black" />
        <path d="M170 350H150V360H170V350Z" fill="black" />
        <path d="M200 350H190V360H200V350Z" fill="black" />
        <path d="M230 350H220V360H230V350Z" fill="black" />
        <path d="M250 350H240V360H250V350Z" fill="black" />
        <path d="M280 350H270V360H280V350Z" fill="black" />
        <path d="M300 350H290V360H300V350Z" fill="black" />
        <path d="M320 350H310V360H320V350Z" fill="black" />
        <path d="M360 350H340V360H360V350Z" fill="black" />
        <path d="M70 360H0V370H70V360Z" fill="black" />
        <path d="M130 360H100V370H130V360Z" fill="black" />
        <path d="M200 360H180V370H200V360Z" fill="black" />
        <path d="M240 360H210V370H240V360Z" fill="black" />
        <path d="M280 360H260V370H280V360Z" fill="black" />
        <path d="M310 360H290V370H310V360Z" fill="black" />
        <path d="M330 360H320V370H330V360Z" fill="black" />
        <path d="M370 360H340V370H370V360Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_184_1235">
          <rect width="370" height="370" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
