export const Logo = () => {
  return (
    <svg
      width="127"
      height="94"
      viewBox="0 0 127 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M82.3154 26.4577V4.5422C82.3154 2.39138 80.8441 0.440637 78.7434 0.0690673C76.7205 -0.288211 74.8886 0.776479 74.0681 2.43425C73.9761 2.62718 73.87 2.70578 73.6649 2.70578C73.4598 2.70578 73.3395 2.62718 73.2617 2.42711C72.5473 0.997992 71.0761 0.00475716 69.3786 0.00475716C67.681 0.00475716 66.2027 1.00514 65.4883 2.44854C65.3964 2.64147 65.2903 2.71293 65.0852 2.71293C64.8801 2.71293 64.7598 2.63433 64.682 2.4414C63.9676 0.997992 62.4964 0.00475716 60.7989 0.00475716C59.1013 0.00475716 57.623 1.00514 56.9086 2.44854C56.8167 2.64147 56.7106 2.71293 56.5055 2.71293C56.3003 2.71293 56.1801 2.62718 56.1023 2.43425C55.2747 0.776479 53.4499 -0.281066 51.4269 0.0762129C49.3262 0.447783 47.855 2.39138 47.855 4.54934V15.5821C47.855 15.9465 47.8126 16.0609 47.2892 15.9394C47.2821 15.9394 47.2679 15.9394 47.2609 15.9322C46.6101 15.7822 45.9099 15.7822 45.1814 16.0037C43.4343 16.5182 42.2884 18.2188 42.2884 20.0624V28.4227C42.2884 28.4227 42.2884 28.4799 42.2884 28.5084V31.7954C42.2884 42.428 50.8186 51.0384 61.3364 51.0384H63.2603C73.7781 51.0384 82.3083 42.4209 82.3083 31.7954V26.4577H82.3154Z"
        fill="#2F66ED"
      />
      <path
        d="M70.3335 32.4295C66.995 32.4295 64.2789 29.6856 64.2789 26.3129C64.2789 22.9402 66.995 20.1963 70.3335 20.1963C73.672 20.1963 76.3881 22.9402 76.3881 26.3129C76.3881 29.6856 73.672 32.4295 70.3335 32.4295Z"
        fill="white"
      />
      <path
        d="M72.0944 28.9214C73.6374 28.9214 74.8883 27.6577 74.8883 26.0989C74.8883 24.54 73.6374 23.2764 72.0944 23.2764C70.5514 23.2764 69.3005 24.54 69.3005 26.0989C69.3005 27.6577 70.5514 28.9214 72.0944 28.9214Z"
        fill="#404040"
      />
      <path
        d="M54.6735 32.4295C51.335 32.4295 48.6189 29.6856 48.6189 26.3129C48.6189 22.9402 51.335 20.1963 54.6735 20.1963C58.0121 20.1963 60.7281 22.9402 60.7281 26.3129C60.7281 29.6856 58.0121 32.4295 54.6735 32.4295Z"
        fill="white"
      />
      <path
        d="M56.4346 28.9214C57.9776 28.9214 59.2285 27.6577 59.2285 26.0989C59.2285 24.54 57.9776 23.2764 56.4346 23.2764C54.8916 23.2764 53.6407 24.54 53.6407 26.0989C53.6407 27.6577 54.8916 28.9214 56.4346 28.9214Z"
        fill="#404040"
      />
      <path
        d="M28.5555 91.0087V87.0453C28.5555 87.0127 28.5371 86.9941 28.5048 86.9941H27.547C27.1741 86.9941 26.93 86.7662 26.93 86.4452C26.93 86.1242 27.1695 85.8916 27.547 85.8916H30.7842C31.1571 85.8916 31.4012 86.1195 31.4012 86.4452C31.4012 86.7708 31.1617 86.9941 30.7842 86.9941H29.8264C29.7941 86.9941 29.7757 87.0127 29.7757 87.0453V91.0087C29.7757 91.4041 29.5179 91.6646 29.1587 91.6646C28.7995 91.6646 28.5509 91.4041 28.5509 91.0087H28.5555Z"
        fill="#2F66ED"
      />
      <path
        d="M31.4519 90.5474C31.369 90.2962 31.3183 89.9984 31.3183 89.5798C31.3183 89.1611 31.3598 88.8587 31.4427 88.6029C31.6821 87.8632 32.3268 87.4492 33.1832 87.4492C34.0397 87.4492 34.689 87.8679 34.9238 88.5936C35.0159 88.8773 35.0574 89.1658 35.0574 89.5426C35.0574 89.7705 34.9146 89.9287 34.6752 89.9287H32.534C32.5017 89.9287 32.4833 89.9473 32.4833 89.9798C32.4833 90.0636 32.5017 90.138 32.5248 90.2171C32.6307 90.5613 32.9392 90.7474 33.3582 90.7474C33.6345 90.7474 33.9016 90.6544 34.118 90.5381C34.2607 90.4543 34.3989 90.4125 34.5186 90.4125C34.7581 90.4125 34.9423 90.5985 34.9423 90.8497C34.9423 91.0265 34.8594 91.1707 34.7074 91.2684C34.3252 91.5382 33.874 91.7057 33.2523 91.7057C32.3222 91.7057 31.6867 91.2777 31.4519 90.5427V90.5474ZM33.8601 89.1518C33.8924 89.1518 33.9108 89.1332 33.9108 89.1006C33.9108 88.9657 33.8924 88.882 33.8694 88.7983C33.7773 88.5145 33.5286 88.3517 33.1971 88.3517C32.8655 88.3517 32.6215 88.5098 32.5294 88.7983C32.5064 88.882 32.4879 88.9657 32.4879 89.1006C32.4879 89.1332 32.5064 89.1518 32.5386 89.1518H33.8647H33.8601Z"
        fill="#2F66ED"
      />
      <path
        d="M38.4141 91.0463V89.1949C38.4141 88.7483 38.1977 88.4552 37.7833 88.4552C37.3689 88.4552 37.157 88.7483 37.157 89.1949V91.0463C37.157 91.4324 36.9084 91.6697 36.5722 91.6697C36.2361 91.6697 35.9874 91.4324 35.9874 91.0463V88.1203C35.9874 87.7342 36.2269 87.4969 36.5722 87.4969C36.8485 87.4969 37.0649 87.6551 37.1294 87.9249H37.1386C37.3366 87.6644 37.6866 87.4551 38.2023 87.4551C39.0956 87.4551 39.5745 88.0877 39.5745 88.9669V91.0463C39.5745 91.4324 39.3351 91.6697 38.9897 91.6697C38.6444 91.6697 38.4049 91.4324 38.4049 91.0463H38.4141Z"
        fill="#2F66ED"
      />
      <path
        d="M40.6106 90.5393C40.5185 90.2462 40.4771 89.9764 40.4771 89.581C40.4771 89.1856 40.5185 88.9158 40.6106 88.6227C40.8501 87.8737 41.4763 87.4551 42.3604 87.4551C43.2445 87.4551 43.8662 87.8737 44.1102 88.6227C44.2023 88.9158 44.2438 89.1856 44.2438 89.581C44.2438 89.9764 44.2023 90.2462 44.1102 90.5393C43.8708 91.2882 43.2445 91.7069 42.3604 91.7069C41.4763 91.7069 40.8547 91.2882 40.6106 90.5393ZM42.9821 90.2602C43.0419 90.0834 43.0557 89.8554 43.0557 89.581C43.0557 89.3065 43.0373 89.0786 42.9821 88.9018C42.89 88.618 42.6828 88.4459 42.3558 88.4459C42.0289 88.4459 41.8217 88.6134 41.7296 88.9018C41.6697 89.0786 41.6559 89.3065 41.6559 89.581C41.6559 89.8554 41.6743 90.0834 41.7296 90.2602C41.8217 90.5439 42.0289 90.716 42.3558 90.716C42.6828 90.716 42.89 90.5486 42.9821 90.2602Z"
        fill="#2F66ED"
      />
      <path
        d="M47.5959 91.0445V89.1931C47.5959 88.7465 47.3795 88.4534 46.9651 88.4534C46.5507 88.4534 46.3388 88.7465 46.3388 89.1931V91.0445C46.3388 91.4306 46.0902 91.6679 45.754 91.6679C45.4179 91.6679 45.1692 91.4306 45.1692 91.0445V86.4671C45.1692 86.081 45.4087 85.8438 45.754 85.8438C46.0994 85.8438 46.3388 86.081 46.3388 86.4671V87.8906H46.348C46.5414 87.6626 46.873 87.4533 47.3887 87.4533C48.282 87.4533 48.7609 88.086 48.7609 88.9652V91.0445C48.7609 91.4306 48.5215 91.6679 48.1761 91.6679C47.8308 91.6679 47.5913 91.4306 47.5913 91.0445H47.5959Z"
        fill="#2F66ED"
      />
      <path
        d="M49.8707 86.468C49.8707 86.0958 50.1608 85.8027 50.5292 85.8027C50.8976 85.8027 51.1877 86.0958 51.1877 86.468C51.1877 86.8401 50.8976 87.1332 50.5292 87.1332C50.1608 87.1332 49.8707 86.8401 49.8707 86.468ZM49.9444 91.0454V88.1194C49.9444 87.7333 50.1839 87.496 50.5292 87.496C50.8746 87.496 51.114 87.7333 51.114 88.1194V91.0454C51.114 91.4315 50.8654 91.6687 50.5292 91.6687C50.1931 91.6687 49.9444 91.4315 49.9444 91.0454Z"
        fill="#2F66ED"
      />
      <path
        d="M52.325 91.0446V88.1186C52.325 87.7325 52.5645 87.4952 52.9098 87.4952C53.1861 87.4952 53.4072 87.6534 53.4762 87.9511V87.9604H53.4854C53.6696 87.6906 54.0426 87.458 54.5031 87.458C54.6873 87.458 54.8208 87.4906 54.9267 87.5417C55.1339 87.6441 55.2352 87.8209 55.2352 88.0209C55.2352 88.3651 55.0004 88.6163 54.6596 88.6163C54.5767 88.6163 54.4939 88.5977 54.411 88.5745C54.3281 88.5512 54.2038 88.5326 54.1117 88.5326C53.6788 88.5326 53.4946 88.8443 53.4946 89.3885V91.0446C53.4946 91.4307 53.246 91.6679 52.9098 91.6679C52.5737 91.6679 52.325 91.4307 52.325 91.0446Z"
        fill="#2F66ED"
      />
      <path
        d="M59.467 91.1208C59.467 91.4324 59.2091 91.6697 58.8822 91.6697C58.6842 91.6697 58.5138 91.5952 58.4079 91.4417L58.3066 91.2975H58.2974C58.0902 91.558 57.6573 91.7115 57.1554 91.7115C56.124 91.7115 55.6221 91.2417 55.6221 90.4648C55.6221 89.567 56.2897 89.153 57.289 89.153H58.0119C58.0441 89.153 58.0626 89.1344 58.0626 89.1018V89.06C58.0626 88.6832 57.7955 88.4552 57.2383 88.4552C56.9897 88.4552 56.741 88.5157 56.5891 88.5715C56.4877 88.6041 56.3957 88.632 56.3128 88.632C56.0365 88.632 55.8477 88.4459 55.8477 88.1622C55.8477 87.9854 55.9306 87.7993 56.147 87.6923C56.4141 87.5574 56.8193 87.4551 57.3811 87.4551C58.5967 87.4551 59.1815 88.0784 59.1815 89.0972V90.209C59.1815 90.4044 59.1999 90.4927 59.2552 90.5951L59.3611 90.7812C59.4302 90.8928 59.4624 91.0091 59.4624 91.1254L59.467 91.1208ZM58.0672 90.2648V89.9857C58.0672 89.9531 58.0488 89.9345 58.0165 89.9345H57.4501C56.9574 89.9345 56.7318 90.088 56.7318 90.3951C56.7318 90.6788 56.9298 90.823 57.3166 90.823C57.8093 90.823 58.0672 90.637 58.0672 90.2602V90.2648Z"
        fill="#2F66ED"
      />
      <path
        d="M63.1829 86.4454C63.1829 86.1151 63.3809 85.8965 63.717 85.8965H66.4661C66.8068 85.8965 67.0325 86.1244 67.0325 86.4361C67.0325 86.7478 66.8068 86.9664 66.4661 86.9664H64.4584C64.4262 86.9664 64.4078 86.985 64.4078 87.0176V88.1526C64.4078 88.1852 64.4262 88.2038 64.4584 88.2038H66.0655C66.4062 88.2038 66.6226 88.4225 66.6226 88.7341C66.6226 89.0458 66.4062 89.2644 66.0655 89.2644H64.4584C64.4262 89.2644 64.4078 89.283 64.4078 89.3156V90.5018C64.4078 90.5344 64.4262 90.553 64.4584 90.553H66.4661C66.8068 90.553 67.0325 90.7809 67.0325 91.0833C67.0325 91.3857 66.8068 91.6229 66.4661 91.6229H63.717C63.3855 91.6229 63.1829 91.4043 63.1829 91.074V86.4501V86.4454Z"
        fill="#2F66ED"
      />
      <path
        d="M68.1746 93.1293C67.843 93.1293 67.6312 92.9199 67.6312 92.6315C67.6312 92.3431 67.8292 92.1338 68.1746 92.1291C68.4232 92.1198 68.566 92.0128 68.6673 91.7151L68.7732 91.4127L67.4839 88.3239C67.4424 88.2216 67.424 88.1378 67.424 88.0448C67.424 87.7238 67.6727 87.4912 67.9996 87.4912C68.2759 87.4912 68.4416 87.6447 68.5429 87.9052L69.3165 89.9846H69.335L70.0533 87.9052C70.1454 87.6447 70.3204 87.4912 70.5874 87.4912C70.9144 87.4912 71.1538 87.7285 71.1538 88.0448C71.1538 88.1378 71.1354 88.2216 71.094 88.3239L69.6527 91.9663C69.3027 92.8502 68.9528 93.1293 68.2298 93.1293H68.1792H68.1746Z"
        fill="#2F66ED"
      />
      <path
        d="M71.6879 90.5474C71.605 90.2962 71.5544 89.9984 71.5544 89.5798C71.5544 89.1611 71.5958 88.8587 71.6787 88.6029C71.9181 87.8632 72.5628 87.4492 73.4193 87.4492C74.2758 87.4492 74.925 87.8679 75.1599 88.5936C75.252 88.8773 75.2934 89.1658 75.2934 89.5426C75.2934 89.7705 75.1507 89.9287 74.9112 89.9287H72.77C72.7378 89.9287 72.7194 89.9473 72.7194 89.9798C72.7194 90.0636 72.7378 90.138 72.7608 90.2171C72.8667 90.5613 73.1752 90.7474 73.5943 90.7474C73.8705 90.7474 74.1376 90.6544 74.354 90.5381C74.4968 90.4543 74.6349 90.4125 74.7546 90.4125C74.9941 90.4125 75.1783 90.5985 75.1783 90.8497C75.1783 91.0265 75.0954 91.1707 74.9434 91.2684C74.5612 91.5382 74.11 91.7057 73.4883 91.7057C72.5582 91.7057 71.9227 91.2777 71.6879 90.5427V90.5474ZM74.0962 89.1518C74.1284 89.1518 74.1468 89.1332 74.1468 89.1006C74.1468 88.9657 74.1284 88.882 74.1054 88.7983C74.0133 88.5145 73.7646 88.3517 73.4331 88.3517C73.1015 88.3517 72.8575 88.5098 72.7654 88.7983C72.7424 88.882 72.724 88.9657 72.724 89.1006C72.724 89.1332 72.7424 89.1518 72.7746 89.1518H74.1008H74.0962Z"
        fill="#2F66ED"
      />
      <path
        d="M78.3095 88.7567C78.3095 87.8821 78.351 87.5891 78.4661 87.2076C78.7654 86.2726 79.4975 85.8027 80.4876 85.8027C81.3348 85.8027 81.9611 86.1981 82.242 86.7098C82.311 86.8261 82.3525 86.9471 82.3525 87.082C82.3525 87.4123 82.1038 87.6356 81.7953 87.6356C81.5881 87.6356 81.4223 87.5518 81.2519 87.3146C81.0447 87.0308 80.8421 86.8959 80.4876 86.8959C80.0317 86.8959 79.7646 87.1378 79.6403 87.5286C79.5758 87.7379 79.5482 87.9426 79.5482 88.7567C79.5482 89.5708 79.5712 89.7754 79.6403 89.9848C79.7646 90.3709 80.0317 90.6174 80.4876 90.6174C80.8467 90.6174 81.0447 90.4825 81.2519 90.1988C81.4177 89.9615 81.5835 89.8778 81.7953 89.8778C82.1038 89.8778 82.3525 90.1057 82.3525 90.4313C82.3525 90.5663 82.311 90.6825 82.242 90.8035C81.9611 91.3152 81.3348 91.7106 80.4876 91.7106C79.4975 91.7106 78.7654 91.2408 78.4661 90.3057C78.351 89.9289 78.3095 89.6312 78.3095 88.7567Z"
        fill="#2F66ED"
      />
      <path
        d="M84.6179 91.6632C83.6924 91.6632 83.3793 91.2027 83.3793 90.37V86.4671C83.3793 86.081 83.6187 85.8438 83.9641 85.8438C84.3094 85.8438 84.5489 86.081 84.5489 86.4671V90.2677C84.5489 90.5049 84.6317 90.6026 84.8159 90.6398C85.1475 90.7003 85.281 90.891 85.281 91.1515C85.281 91.4353 85.0968 91.6632 84.6732 91.6632H84.6225H84.6179Z"
        fill="#2F66ED"
      />
      <path
        d="M86.1007 86.468C86.1007 86.0958 86.3908 85.8027 86.7592 85.8027C87.1276 85.8027 87.4177 86.0958 87.4177 86.468C87.4177 86.8401 87.1276 87.1332 86.7592 87.1332C86.3908 87.1332 86.1007 86.8401 86.1007 86.468ZM86.1744 91.0454V88.1194C86.1744 87.7333 86.4138 87.496 86.7592 87.496C87.1045 87.496 87.344 87.7333 87.344 88.1194V91.0454C87.344 91.4315 87.0953 91.6687 86.7592 91.6687C86.423 91.6687 86.1744 91.4315 86.1744 91.0454Z"
        fill="#2F66ED"
      />
      <path
        d="M91.0645 91.0463V89.1949C91.0645 88.7483 90.8481 88.4552 90.4337 88.4552C90.0192 88.4552 89.8074 88.7483 89.8074 89.1949V91.0463C89.8074 91.4324 89.5588 91.6697 89.2226 91.6697C88.8865 91.6697 88.6378 91.4324 88.6378 91.0463V88.1203C88.6378 87.7342 88.8773 87.4969 89.2226 87.4969C89.4989 87.4969 89.7153 87.6551 89.7798 87.9249H89.789C89.987 87.6644 90.337 87.4551 90.8527 87.4551C91.746 87.4551 92.2249 88.0877 92.2249 88.9669V91.0463C92.2249 91.4324 91.9855 91.6697 91.6401 91.6697C91.2948 91.6697 91.0553 91.4324 91.0553 91.0463H91.0645Z"
        fill="#2F66ED"
      />
      <path
        d="M93.4175 86.468C93.4175 86.0958 93.7076 85.8027 94.076 85.8027C94.4444 85.8027 94.7345 86.0958 94.7345 86.468C94.7345 86.8401 94.4444 87.1332 94.076 87.1332C93.7076 87.1332 93.4175 86.8401 93.4175 86.468ZM93.4912 91.0454V88.1194C93.4912 87.7333 93.7306 87.496 94.076 87.496C94.4214 87.496 94.6608 87.7333 94.6608 88.1194V91.0454C94.6608 91.4315 94.4121 91.6687 94.076 91.6687C93.7399 91.6687 93.4912 91.4315 93.4912 91.0454Z"
        fill="#2F66ED"
      />
      <path
        d="M95.8902 90.5474C95.8073 90.3055 95.7567 90.0077 95.7567 89.5798C95.7567 89.1518 95.8073 88.8587 95.8902 88.6122C96.1297 87.8539 96.7559 87.4492 97.6032 87.4492C98.1603 87.4492 98.6116 87.6353 98.8695 87.8958C99.0352 88.0633 99.1043 88.1982 99.1043 88.3656C99.1043 88.6587 98.8879 88.868 98.5978 88.868C98.4228 88.868 98.2893 88.775 98.1235 88.6261C97.99 88.5005 97.8426 88.4401 97.6262 88.4401C97.3177 88.4401 97.1105 88.5843 97.0184 88.8866C96.9677 89.0448 96.9447 89.2402 96.9447 89.5751C96.9447 89.9101 96.9677 90.1054 97.0184 90.2636C97.1105 90.566 97.3177 90.7102 97.6262 90.7102C97.8426 90.7102 97.9946 90.6497 98.1235 90.5241C98.2893 90.3706 98.4228 90.2822 98.5978 90.2822C98.8879 90.2822 99.1043 90.4915 99.1043 90.7846C99.1043 90.9521 99.0398 91.087 98.8695 91.2544C98.6116 91.515 98.1603 91.701 97.6032 91.701C96.7559 91.701 96.1297 91.2963 95.8902 90.5381V90.5474Z"
        fill="#2F66ED"
      />
      <path
        d="M77.1215 61.9047C76.4584 60.8208 74.3311 59.1368 72.3648 58.8624C71.2321 58.7042 70.5045 59.281 70.3664 59.9276C70.2513 60.4766 70.546 61.1511 71.4623 61.4209C72.9128 61.8954 73.7232 62.5652 74.8928 63.7887C75.3717 64.2911 75.869 64.4911 76.2973 64.4911C76.5874 64.4911 76.8452 64.3981 77.0386 64.2446C77.5498 63.8445 77.7708 62.9653 77.1215 61.9047Z"
        fill="#2F66ED"
      />
      <path
        d="M61.1477 65.705C58.7118 64.3606 57.7218 63.1046 56.985 61.6392C56.6443 60.9647 56.0825 60.56 55.4471 60.5414C54.8945 60.5228 54.3788 60.7972 54.1071 61.2578C53.8308 61.723 53.8492 62.3044 54.1577 62.8348C54.1992 62.9138 54.236 62.9929 54.2682 63.0674C54.3005 63.1418 54.3557 63.2906 54.3603 63.2999C54.6735 64.0675 55.1109 65.0537 55.18 66.4027C55.1984 67.3564 55.0741 68.3984 54.7748 69.4683C53.8216 72.8968 51.8323 75.3576 49.309 74.59C46.8132 73.8271 46.9467 70.9523 47.8999 67.5238C48.1624 66.5795 48.5308 65.7143 48.9636 64.9746C50.0733 63.272 51.1877 62.6719 51.6528 62.3789C51.662 62.3789 51.6712 62.3696 51.6758 62.3696C51.7264 62.337 51.7817 62.2998 51.8323 62.2719C52.3987 61.9788 52.7303 61.495 52.7533 60.9368C52.7717 60.3972 52.4862 59.8669 52.0304 59.5924C51.5791 59.3179 51.0357 59.3319 50.5338 59.625C49.6359 60.1506 47.8769 60.4577 46.0442 60.3972C45.3075 60.3786 44.7042 60.6623 44.4049 61.188C44.1517 61.6346 44.1655 62.1788 44.4418 62.6161C44.7687 63.1371 45.395 63.4023 46.1593 63.3418C46.4448 63.3186 46.6981 63.2999 46.9283 63.2767C47.1125 63.2674 47.1632 63.3697 47.0665 63.5465C45.9015 65.2165 44.4234 68.0821 44.6029 71.6826C44.7733 75.2739 46.7672 76.5857 47.9368 77.0462C48.5676 77.2928 49.2399 77.4137 49.9214 77.4137C51.5515 77.4137 53.2276 76.7299 54.4801 75.4739C56.8008 73.148 57.6435 69.8079 57.6297 67.4215C57.6251 67.0075 57.7862 66.8633 57.975 67.0401C58.3941 67.4587 58.9236 67.9239 59.5176 68.3007C60.0702 68.6496 60.678 68.6868 61.1845 68.4077C61.6634 68.1425 61.9811 67.6215 61.9858 67.0866C61.995 66.5376 61.691 66.0446 61.1477 65.7236V65.705Z"
        fill="#2F66ED"
      />
      <path
        d="M16.3991 60.2112C15.9386 59.732 15.2709 59.5692 14.6217 59.7693C12.1858 60.5229 5.85888 62.2859 3.86965 62.779C3.45062 62.886 2.82898 63.0116 2.28562 63.1279C1.89422 63.2116 1.54426 63.2814 1.34626 63.3326C0.701598 63.4721 0.236522 63.8769 0.0661477 64.4444C-0.0996222 64.998 0.0523353 65.6306 0.452946 66.0493C0.890394 66.5052 1.55347 66.654 2.29483 66.4493C2.65861 66.3191 3.2434 66.0958 3.93411 65.8306C4.57417 65.5887 5.32474 65.3422 6.05228 65.0305C6.40224 64.8817 6.59564 65.0724 6.50355 65.2538C6.43908 65.3887 6.35619 65.519 6.28252 65.6632C4.93333 68.1798 4.94715 71.1989 6.32856 73.5388C7.70998 75.888 10.2011 77.1812 13.3416 77.1812C14.0553 77.1812 14.6263 76.8881 14.9118 76.3811C15.1604 75.9345 15.1466 75.3716 14.8795 74.9204C14.5618 74.3901 13.9586 74.0924 13.231 74.1203C11.205 74.1854 9.61173 73.3899 8.73683 71.8874C7.79747 70.2685 7.85273 68.1054 8.87498 66.3749C9.43675 65.4166 10.5373 64.2118 11.624 63.821C12.2456 63.5977 13.3784 63.4303 14.3822 63.2814L14.967 63.193C16.2748 62.9883 16.6846 62.2999 16.8181 61.9091C17.0069 61.3416 16.8411 60.6764 16.3991 60.2158V60.2112Z"
        fill="#2F66ED"
      />
      <path
        d="M39.1832 66.3391C38.7135 64.4086 37.5209 62.8176 35.7434 61.7384C32.6122 59.8311 27.9983 60.073 24.7796 62.3199C21.28 64.7714 19.6822 69.5349 21.2846 72.7261C21.9477 74.0379 22.9515 74.9032 24.0429 75.0986C24.9822 75.266 25.9262 74.9404 26.7734 74.1496C28.7028 72.34 30.6276 68.7999 31.4703 65.7111C31.6268 65.1715 31.8202 64.3202 31.9722 63.5945C32.032 63.3154 32.1702 63.106 32.4925 63.1898C33.0819 63.3526 33.6345 63.5945 34.1456 63.9248C36.5124 65.4459 37.0604 68.5673 35.4211 71.177C34.689 72.3447 32.9115 73.4192 30.996 73.8519C30.2638 74.024 29.7481 74.4473 29.5823 75.0195C29.4396 75.5079 29.5731 76.0243 29.9369 76.4057C30.2638 76.75 30.7243 76.9267 31.2538 76.9267C31.4749 76.9267 31.7051 76.8942 31.9399 76.8337C34.5646 76.1359 36.9913 74.4752 38.1287 72.5959C39.2937 70.6607 39.6759 68.3813 39.1786 66.3391H39.1832ZM25.0513 71.698C24.8763 71.8516 24.7013 71.9167 24.531 71.8841C24.2823 71.8376 24.0336 71.6004 23.8402 71.2236C22.8871 69.4605 24.0429 66.274 26.2623 64.5388C26.8655 64.0736 27.4595 63.748 28.1595 63.4782C28.5739 63.3247 28.7627 63.6177 28.7443 63.9201C28.7351 63.9899 28.7305 64.0597 28.7212 64.1295C28.3068 66.8136 26.6491 70.2839 25.0559 71.698H25.0513Z"
        fill="#2F66ED"
      />
      <path
        d="M91.2487 73.1724V62.3708C91.2487 61.0124 90.1527 59.9053 88.8128 59.9053H86.515C85.1704 59.9053 84.0791 61.0124 84.0791 62.3708V73.1724C84.0791 74.5308 85.175 75.6332 86.515 75.6332H88.8128C90.1574 75.6332 91.2487 74.5261 91.2487 73.1724ZM86.6946 62.1847H88.624C88.8266 62.1847 88.9924 62.3522 88.9924 62.5568V64.5106H86.3308V62.5568C86.3308 62.3522 86.4966 62.1847 86.6992 62.1847H86.6946ZM88.9878 66.79V68.7345H86.3262V66.79H88.9878ZM88.6194 73.3538H86.69C86.4874 73.3538 86.3216 73.1864 86.3216 72.9817V71.0139H88.9831V72.9817C88.9831 73.1864 88.8174 73.3538 88.6148 73.3538H88.6194Z"
        fill="#2F66ED"
      />
      <path
        d="M102.627 75.8428C101.001 75.1403 99.9377 74.0983 99.2378 72.9632C100.421 72.5585 101.554 71.8328 102.341 70.935C102.756 70.4652 102.714 69.7442 102.249 69.3255C101.784 68.9068 101.071 68.9487 100.656 69.4185C100.085 70.0651 99.2102 70.6001 98.3307 70.8606C98.1511 70.2093 98.059 69.5767 98.013 68.9952H98.6024C100.016 68.9952 101.167 67.8322 101.167 66.4041V61.5755C101.167 60.1473 100.016 58.9844 98.6024 58.9844H94.8818C93.4681 58.9844 92.3169 60.1473 92.3169 61.5755V75.5311H92.386C91.7736 75.7125 91.1934 75.8428 90.7191 75.9032C90.1021 75.987 89.6646 76.5591 89.7475 77.1825C89.8212 77.7547 90.3093 78.1733 90.8665 78.1733C90.9171 78.1733 90.9677 78.1733 91.0184 78.164C92.2571 77.9966 95.8948 77.1081 98.0314 75.1636C98.9063 76.2428 100.104 77.2197 101.738 77.9268C101.881 77.9873 102.033 78.0198 102.18 78.0198C102.618 78.0198 103.037 77.7593 103.216 77.3267C103.46 76.7499 103.198 76.08 102.622 75.8288L102.627 75.8428ZM98.4182 66.7204H95.066C94.7943 66.7204 94.5732 66.4971 94.5732 66.2227V65.1341H98.9063V66.2227C98.9063 66.4971 98.6853 66.7204 98.4136 66.7204H98.4182ZM95.066 61.2731H98.4182C98.6899 61.2731 98.9109 61.4964 98.9109 61.7708V62.8594H94.5779V61.7708C94.5779 61.4964 94.7989 61.2731 95.0706 61.2731H95.066ZM94.5732 74.7123V68.9812C94.6745 68.9952 94.7759 68.9999 94.8818 68.9999H95.7521C95.8349 70.1768 96.0974 71.6979 96.7973 73.2005C96.2862 73.7634 95.4758 74.2797 94.5732 74.7077V74.7123Z"
        fill="#2F66ED"
      />
      <path
        d="M78.2452 69.204C75.9796 66.1105 71.246 67.8643 69.51 68.6086C69.2153 68.7388 68.8976 68.7016 68.916 68.1899C68.916 68.1853 68.916 68.1713 68.916 68.1667C68.9298 66.7385 69.3258 64.8545 69.731 63.9474C70.081 63.1705 69.6067 62.203 68.8423 61.9983C67.8477 61.7285 66.9406 62.4821 66.7149 63.2589C66.3742 64.4312 65.3888 68.8365 66.3558 71.1485C66.5169 71.5393 66.8439 71.8416 67.2537 71.9765C67.6589 72.1114 68.1056 72.0742 68.4785 71.8649C70.7993 70.5717 75.16 68.5062 76.4079 70.6275C77.5545 72.572 74.3265 75.1445 69.0541 74.6793C68.2345 74.6048 67.355 75.1352 67.3918 76.1353C67.4241 77.0564 68.322 77.5681 69.1324 77.5681C69.1462 77.5681 69.1554 77.5681 69.1693 77.5681C74.2575 77.4425 77.3334 76.0004 78.5767 73.6791C79.5805 71.7998 78.7977 69.953 78.2498 69.2087L78.2452 69.204Z"
        fill="#2F66ED"
      />
      <path
        d="M115.921 70.3951C115.198 69.0367 113.904 67.5063 112.269 66.8736H114.074C114.719 66.8736 115.244 66.3433 115.244 65.692C115.244 65.0408 114.719 64.5105 114.074 64.5105H112.223V62.1241C113.084 61.8496 113.844 61.5007 114.415 61.096C114.945 60.7192 115.069 59.9842 114.701 59.4492C114.328 58.9143 113.6 58.7887 113.071 59.1608C112.21 59.7749 110.22 60.3936 108.088 60.3936C107.444 60.3936 106.919 60.9239 106.919 61.5751C106.919 62.2264 107.444 62.7567 108.088 62.7567C108.682 62.7567 109.29 62.7148 109.884 62.6358V64.5105H107.803C107.158 64.5105 106.633 65.0408 106.633 65.692C106.633 66.3433 107.158 66.8736 107.803 66.8736H109.7C109.286 68.3296 108.125 70.2416 105.823 72.0325C105.312 72.4326 105.215 73.1722 105.611 73.6886C105.841 73.991 106.187 74.1491 106.537 74.1491C106.785 74.1491 107.038 74.0701 107.25 73.9026C108.323 73.0653 109.193 72.1954 109.889 71.3301V77.4008C109.889 78.0521 110.414 78.5824 111.058 78.5824C111.703 78.5824 112.228 78.0521 112.228 77.4008V69.5438C112.84 70.009 113.434 70.7068 113.863 71.5115C114.074 71.9069 114.475 72.1349 114.894 72.1349C115.083 72.1349 115.272 72.0884 115.447 71.9953C116.018 71.6883 116.229 70.9719 115.926 70.3951H115.921Z"
        fill="#2F66ED"
      />
      <path
        d="M126.871 68.7534C126.581 68.1719 125.876 67.9347 125.301 68.2278L123.284 69.2558V60.3847C123.284 59.7334 122.759 59.2031 122.114 59.2031C121.47 59.2031 120.945 59.7334 120.945 60.3847V70.4513L115.884 73.0285C115.309 73.3216 115.074 74.0333 115.364 74.6148C115.571 75.0288 115.981 75.266 116.409 75.266C116.584 75.266 116.764 75.2242 116.934 75.1404L120.945 73.0983V77.4059C120.945 78.0572 121.47 78.5875 122.114 78.5875C122.759 78.5875 123.284 78.0572 123.284 77.4059V71.9074L126.355 70.3444C126.931 70.0513 127.166 69.3396 126.876 68.7581L126.871 68.7534Z"
        fill="#2F66ED"
      />
      <path
        d="M116.151 64.0681L118.5 65.687C118.702 65.8265 118.932 65.8917 119.158 65.8917C119.531 65.8917 119.899 65.7102 120.125 65.3753C120.489 64.8357 120.351 64.1007 119.817 63.7332L117.468 62.1144C116.934 61.7469 116.206 61.8864 115.843 62.426C115.479 62.9656 115.617 63.7006 116.151 64.0681Z"
        fill="#2F66ED"
      />
      <path
        d="M116.151 67.9851L118.5 69.604C118.702 69.7435 118.932 69.8087 119.158 69.8087C119.531 69.8087 119.899 69.6272 120.125 69.2923C120.489 68.7527 120.351 68.0177 119.817 67.6502L117.468 66.0313C116.934 65.6638 116.206 65.8034 115.843 66.343C115.479 66.8826 115.617 67.6176 116.151 67.9851Z"
        fill="#2F66ED"
      />
    </svg>
  );
};
