import React from "react";

import { SxProps, Theme, Box, styled, Typography as MuiTypography } from "@mui/material";

import { theme } from "../../../providers/ThemeProviderWrap";
import { ErrorIcon } from "../Icon/ErrorIcon";

const FontSize = {
  ExtraLarge: "extraLarge",
  Large: "large",
  Medium: "medium",
  Small: "small",
  ExtraSmall: "extraSmall",
  H3: "h3",
} as const;


export type Props = {
  errorText: string;
  cancelError?: boolean;
  marginTop?: string;
};

// eslint-disable-next-line
export type FontSize = (typeof FontSize)[keyof typeof FontSize];
const FontSizeStyle: Record<FontSize, number> = {
  extraLarge: 24,
  large: 20,
  medium: 16,
  small: 14,
  extraSmall: 12,
  h3: 18,
};

const FontWeight = {
  Regular: "regular",
  Bold: "bold",
} as const;
// eslint-disable-next-line
export type FontWeight = (typeof FontWeight)[keyof typeof FontWeight];
const FontWeightStyle: Record<FontWeight, number> = {
  regular: 400,
  bold: 700,
};

type TypographyProps = {
  size?: FontSize;
  weight?: FontWeight;
  children: React.ReactNode;
  sx?: SxProps<Theme> | undefined;
};

export const Typography: React.FC<TypographyProps> = ({
  size = "medium",
  weight = "regular",
  children,
  sx,
}) => {
  return (
    <StyledTitle
      sx={{
        fontSize: FontSizeStyle[size],
        fontWeight: FontWeightStyle[weight],
        ...sx,
      }}
    >
      {children}
    </StyledTitle>
  );
};

export const StyledTitle = styled(MuiTypography)`
  &[data-required="true"] {
    &::after {
      content: "*";
      margin-left: ${(props) => props.theme.spacing(2)}px;
      font-size: 18px;
      color: red;
    }
  }
`;

export const GrayTypography: React.FC<TypographyProps> = ({
  size = "medium",
  weight = "regular",
  children,
}) => {
  return (
    <StyledTitle
      sx={{
        color: theme.palette.grey[600],
        fontSize: FontSizeStyle[size],
        fontWeight: FontWeightStyle[weight],
      }}
    >
      {children}
    </StyledTitle>
  );
};

export const Title = styled(Typography)`
  margin-top: 20px;
  width: calc(240px - ${(props) => props.theme.spacing(2)}px * 3);
  &[data-required="true"] {
    &::after {
      content: "*";
      margin-left: ${(props) => props.theme.spacing(2)}px;
      font-size: 18px;
      color: red;
    }
  }
  //font-family: Noto Sans JP;
  font-family: YuGothic;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #4b4b4b;
`;


export const ErrorTypography = ({ errorText, marginTop }: Props) => {
  return (
    <MuiTypography
      variant="regular14"
      color="#E31717"
      height={'100%'}
      borderRadius={"6px"}
      gap={"40x"}
      display={"flex"}
      justifyContent={"left"}
      alignItems={"center"}
      mt={marginTop || "7.5px"}
      sx={{ 
        backgroundColor: "#FFF2F3", 
        wordBreak: "break-word",
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word" 
      }}
      paddingX="10px"
      paddingY={0.5}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        //alignItems={"center"}
      >

        <Box
          sx={{
            //marginTop: "-4px",
            //top: '-4px',
            //paddingTop: '5px',
            marginLeft: "5px",
            marginRight: "5px",
            alignItems: "center",
            display: "flex",
            height: '8px',
            paddingY: 0.8
          }}
        >
          <ErrorIcon />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: "center",
            paddingLeft: '0.1em',
            //height: '100%'
            //textIndent: '1rm'
          }}
        >
          {errorText}
        </Box>
      </Box>
    </MuiTypography>
  );
};
