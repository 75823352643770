export const API_GATEWAY_ENDPOINT =
  process.env.REACT_APP_API_GATEWAY_ENDPOINT || "";
export const INSURANCE_CARD_BUCKET =
  process.env.REACT_APP_INSURANCE_CARD_BUCKET || "";
export const PATIENT_EYES_BUCKET =
  process.env.REACT_APP_PATIENT_EYES_BUCKET || "innojin-patient-eyes-bucket";
export const LEDGER_BUCKET =
  process.env.REACT_APP_LEDGER_BUCKET || "";
export const STRIPE_PUBLISHABLE_API_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY || "";
export const COGNITO_DOMAIN =
  process.env.REACT_APP_COGNITO_DOMAIN || "";
export const COGNITO_CLIENT_ID =
  process.env.REACT_APP_COGNITO_CLIENT_ID || "";
export const RUM_POOL_ID =
  process.env.REACT_APP_RUM_POOL_ID || "";
export const RUM_APPLICATION_ID =
  process.env.REACT_APP_RUM_APPLICATION_ID || "";
export const ADDITIONAL_PATIENT_DOCUMENTS_BUCKET =
  process.env.REACT_APP_ADDITIONAL_PATIENT_DOCUMENTS_BUCKET || "";
export const REACT_APP_MEDICAL_CERTIFICATE_BUCKET =
  process.env.REACT_APP_MEDICAL_CERTIFICATE_BUCKET || "";
// FIXME: stgで真っ白になるため一旦こちらを削除
// export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY || "";
// export const FIREBASE_AUTH_DOMAIN =
//   process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "";
// export const FIREBASE_PROJECT_ID =
//   process.env.REACT_APP_FIREBASE_PROJECT_ID || "";
// export const FIREBASE_STORAGE_BUCKET =
//   process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "";
// export const FIREBASE_MESSAGING_SENDER_ID =
//   process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "";
// export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID || "";
// export const FIREBASE_MEASUREMENT_ID =
//   process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "";
// export const FIREBASE_APP_IDENTITY =
//   process.env.REACT_APP_FIREBASE_APP_IDENTITY || "";
export const ENV =
  process.env.REACT_APP_ENV || "";
// FIXME: stgで真っ白になるため一旦こちらを削除
// export const FIREBASE_SERVICE_ACCOUNT_TYPE =
//   process.env.FIREBASE_SERVICE_ACCOUNT_TYPE || "";
// export const FIREBASE_SERVICE_ACCOUNT_PROJECT_ID =
//   process.env.FIREBASE_SERVICE_ACCOUNT_PROJECT_ID || "";
// export const FIREBASE_SERVICE_ACCOUNT_PRIVATE_KEY_ID =
//   process.env.FIREBASE_SERVICE_ACCOUNT_PRIVATE_KEY_ID || "";
// export const FIREBASE_SERVICE_ACCOUNT_PRIVATE_KEY =
//   process.env.FIREBASE_SERVICE_ACCOUNT_PRIVATE_KEY || "";
// export const FIREBASE_SERVICE_ACCOUNT_CLIENT_EMAIL =
//   process.env.FIREBASE_SERVICE_ACCOUNT_CLIENT_EMAIL || "";
// export const FIREBASE_SERVICE_ACCOUNT_CLIENT_ID =
//   process.env.FIREBASE_SERVICE_ACCOUNT_CLIENT_ID || "";
// export const FIREBASE_SERVICE_ACCOUNT_AUTH_URI =
//   process.env.FIREBASE_SERVICE_ACCOUNT_AUTH_URI || "";
// export const FIREBASE_SERVICE_ACCOUNT_TOKEN_URI =
//   process.env.FIREBASE_SERVICE_ACCOUNT_TOKEN_URI || "";
// export const FIREBASE_SERVICE_ACCOUNT_AUTH_PROVIDER_X509_CERT_URL =
//   process.env.FIREBASE_SERVICE_ACCOUNT_AUTH_PROVIDER_X509_CERT_URL || "";
// export const FIREBASE_SERVICE_ACCOUNT_CLIENT_X509_CERT_URL =
//   process.env.FIREBASE_SERVICE_ACCOUNT_CLIENT_X509_CERT_URL || "";
// export const FIREBASE_SERVICE_ACCOUNT_UNIVERSE_DOMAIN =
//   process.env.FIREBASE_SERVICE_ACCOUNT_UNIVERSE_DOMAIN || "";
