import { useGetPatientHook } from "api-client";
import { userState } from "atoms/userState";
import { selector } from "recoil";

export const me = selector({
  key: "me",
  get: async ({ get }) => {
    const existingData = get(userState);
    const patient = await useGetPatientHook();
    const me = patient();

    if (!me) return existingData;

    return me;
  },
});
