import React from "react";

import { PCDisplay } from "components/organisms/PCDisplay";
import { Pages } from "pages/router";
import { useMobileTypeContext } from "providers/MobileTypeContextProvider";

export const PatientScreen: React.FC = () => {
  const mobile = useMobileTypeContext();
  const isNotPC = !mobile?.isPC || mobile?.isDisplay;

  if (isNotPC) return <Pages />;

  return <PCDisplay />;
};
