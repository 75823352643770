import React, { useEffect, useState, useCallback } from "react";

import { decodeToken } from "react-jwt";

import { useVerifyHook } from "../api-client/common/common";
import { useLocalStorage } from "../hooks/useLocalStorage";

/**
 * 認証情報でlocalStorageに投入したデータの型
 */
type AuthInfo = {
  idToken: string;
  accessToken: string;
  refreshToken: string;
};
type DecodedJwt = {
  sub: string;
  "cognito:groups": string[];
  email: string;
};
// ログイン状態のContext
/**
 * true: ログイン状態
 * false: ログアウト状態
 * null 未認証状態
 */
export const LoggedInContext = React.createContext<
  [boolean | null, () => void]
>([null, () => {}]);

// 認証情報と認証情報セットのContext
export const AuthInfoContext = React.createContext<
  [
    AuthInfo,
    (idToken: string, accessToken: string, refreshToken: string) => void
  ]
>([
  {
    idToken: "",
    accessToken: "",
    refreshToken: "",
  },
  () => {},
]);

export const AuthContextProvider: React.FC<React.PropsWithChildren> = (
  props
) => {
  // stateの定義
  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
  const [authInfo, setAuthInfo] = useState<AuthInfo>({
    idToken: "",
    accessToken: "",
    refreshToken: "",
  });
  const [idToken, updateIdToken, deleteIdToken] = useLocalStorage("idToken");
  const [accessToken, updateAccessToken, deleteAccessToken] =
    useLocalStorage("accessToken");
  const [refreshToken, updateRefreshToken, deleteRefreshToken] =
    useLocalStorage("refreshToken");
  const [, updatePatientId] = useLocalStorage("patientId");

  const checkVerify = useCallback(async () => {
    // eslint-disable-next-line
    const verify = useVerifyHook();
    try {
      await verify();
    } catch (e: any) {
      setLoggedIn(false);
      return;
    }
    setLoggedIn(true);
  }, []);

  const setToken = useCallback(
    (idToken: string, accessToken: string, refreshToken: string) => {
      const decodedToken = decodeToken<DecodedJwt>(idToken);
      if (
        decodedToken?.sub &&
        decodedToken?.["cognito:groups"].includes("patient")
      ) {
        updatePatientId(decodedToken.sub);
      }
      localStorage.email = decodedToken?.email;
      setAuthInfo({ idToken, accessToken, refreshToken });
      updateIdToken(idToken);
      updateAccessToken(accessToken);
      updateRefreshToken(refreshToken);
    },
    [updatePatientId, updateIdToken, updateAccessToken, updateRefreshToken]
  );

  useEffect(() => {
    checkVerify();
  }, [authInfo, checkVerify, idToken, accessToken, refreshToken]);

  const logout = useCallback(() => {
    setLoggedIn(false);
    setAuthInfo({
      idToken: "",
      accessToken: "",
      refreshToken: "",
    });
    deleteIdToken();
    deleteAccessToken();
    deleteRefreshToken();
  }, [deleteIdToken, deleteAccessToken, deleteRefreshToken]);

  return (
    <LoggedInContext.Provider value={[loggedIn, logout]}>
      <AuthInfoContext.Provider value={[authInfo, setToken]}>
        {props.children}
      </AuthInfoContext.Provider>
    </LoggedInContext.Provider>
  );
};
