export const specialStringRegex = /[-/\\^$*+?.()|[\]{}]/g;
export const nameRegex = /\S+/;
export const katakanaRegex =　/^([\u{0020}\u{3000}-\u{301C}\u{30A1}-\u{30F6}\u{30FB}-\u{30FE}])+$/mu;
export const urlRegex = /https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+$/;
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
export const phoneNumberRegex = /^[0-9]{10,11}$/;
export const phoneNumberEnRegex = /^\+81\d{9,13}$/;
export const verifyCodeRegex = /^[0-9]{6}$/;
export const patientNumberRegex = /^\d+$/;
export const priceRegex = /^([1-9]\d*|0)$/;
export const bankAccountNumberRegex = /^\d{7}$/;
export const branchCodeRegex = /^\d{3}$/;
export const postalCodeRegex = /^(\d{7})$/;
export const addressRegex = /\S+/;
export const address_2Regex = /^[0-9-]+$/;
export const onChangeCatchCopyRegex = /^.{0,30}$/;
export const symbolRegex =
  /[!"#$%&'()☆＊※@☆*+\-.,/:;<=>?@＠[\\\]^_`{|}~【】￥「」]/g;
export const percentRegex = /^[1-9][0-9]?$/;
//const halfSizeNumber = /^([1-9]\d*|0)(\.\d{1,2})?$/;
export const halfSizeNumber = /^[0-9]+$/;
export const decimalNumber = /^[0-9]+(\.[0-9]+)+$/; // 浮動小数点を許容
export const atLeast8HhalfSizeNumberAlphanumericCharacters =
  /^([a-zA-Z0-9!-/:-@¥[-`{-~]{8,256})$/; // 半角英数字8文字以上

/*export const isMatchValidation = (name: string, data: any) => {
  if (data !== '') {
    switch (name) {
      case 'name':
      case 'title':
      case 'nickName':
      case 'lastNameKanji':
      case 'firstNameKanji':
      case 'occupation': {
        if (specialStringRegex.test(data)) {
          return false;
        } else {
          return true
        }
      }
      case 'empty': {
        if (data === '') {
          return false;
        } else {
          return true
        }
      }
      case 'katakana':
      case 'lastNameKana':
      case 'firstNameKana':
      case 'bankAccountHolder': {
        if (katakanaRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'url': {
        if (urlRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'email': {
        if (emailRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'phoneNumber': {
        if (phoneNumberRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'phoneNumberEn': {
        if (phoneNumberEnRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'halfSizeNumber': {
        if (halfSizeNumber.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'decimalNumber': {
        if (decimalNumber.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'verifyCode': {
        if (verifyCodeRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'patientNumber': {
        if (patientNumberRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'regularPrice':
      case 'memberPrice':
      case 'membershipPrice':
      case 'backNumberPrice':
      case 'forFreeUserPrice':
      case 'forSubscriberPrice':
      case 'defaultStock': {
        if (priceRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'bankAccountNumber': {
        if (bankAccountNumberRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'branchCode': {
        if (branchCodeRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'postalCode': {
        if (postalCodeRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'address_2': {
        if (address_2Regex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'onChangeCatchCopy': {
        if (onChangeCatchCopyRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'symbol': {
        if (symbolRegex.test(data)) {
          return true;
        }
        return false;
      }
      case 'percent': {
        if (percentRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'previousPassword':
      case 'proposedPassword':
      case 'confirmationProposedPassword':
        {
          if (atLeast8HhalfSizeNumberAlphanumericCharacters.test(data)) {
            return true;
          } else {
            return false;
          }
        }
      default: {
        break;
      }
    }
  } else {
    return false;
  }
  return false;
};*/
