/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * inojin-stg-api
 * OpenAPI spec version: 1.0.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  GetPatientResponseResponse,
  BadRequestResponse,
  InternalServerErrorResponse,
  PatientSignUpResponseResponse,
  PatientSignUpBodies,
  SuccessResponse,
  PatientConfirmSignUpBodies,
  SocialLoginCallbackResponseResponse,
  PatientSocialLoginCallbackBodies,
  PostPatientSetupIntentsResponseResponse,
  PostPatientPaymentMethodBodies,
  PatchPatientPaymentMethodBodies,
  SuccessPostResponseIdResponse,
  PostConsultationsBodies,
  SuccessResponseResponse,
  PostOrcaPatientBodies,
  GetSameTimeConsultations200,
  GetSameTimeConsultationsParams,
  GetConsultationRoomStatus200,
  GetConsultationRoomStatusParams
} from '.././model'
import { useCustomClient } from '../../apiClientConfig/customClient';
import type { ErrorType, BodyType } from '../../apiClientConfig/customClient';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


/**
 * # 仕様
- ログインしていることが前提
- access tokenのsub(=userId)をkeyに患者情報を取得

# 画面
- https://onl.tw/YWGHAxS
 * @summary 患者情報取得API
 */
export const useGetPatientHook = () => {
        const getPatient = useCustomClient<GetPatientResponseResponse>();

        return (
    
 signal?: AbortSignal
) => {
        return getPatient(
          {url: `/v1/patient`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetPatientQueryKey = () => [`/v1/patient`] as const;
  

    
export const useGetPatientQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetPatientHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPatientHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPatientHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPatientQueryKey();

  const getPatient =  useGetPatientHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPatientHook>>>> = ({ signal }) => getPatient(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetPatientQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPatientHook>>>>
export type GetPatientQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary 患者情報取得API
 */
export const useGetPatient = <TData = Awaited<ReturnType<ReturnType<typeof useGetPatientHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPatientHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetPatientQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * # 仕様
- メールアドレスとパスワードでサインアップする。
- パスワードの文字数は8桁以上とする。
- メールアドレスに6桁の認証コードを送信する。
- 後続のconfirmSignUp時にユーザーIDが必要になるためレスポンスとして返却する。

# 画面
- https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=1-6533&t=9hy4EnTx0I8U9Zi9-4
 * @summary 患者サインアップAPI
 */
export const usePatientSignUpHook = () => {
        const patientSignUp = useCustomClient<PatientSignUpResponseResponse>();

        return (
    patientSignUpBodies: BodyType<PatientSignUpBodies>,
 ) => {
        return patientSignUp(
          {url: `/v1/patient/signUp`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: patientSignUpBodies
    },
          );
        }
      }
    


export const usePatientSignUpMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatientSignUpHook>>>, TError,{data: BodyType<PatientSignUpBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatientSignUpHook>>>, TError,{data: BodyType<PatientSignUpBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patientSignUp =  usePatientSignUpHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatientSignUpHook>>>, {data: BodyType<PatientSignUpBodies>}> = (props) => {
          const {data} = props ?? {};

          return  patientSignUp(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatientSignUpMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatientSignUpHook>>>>
    export type PatientSignUpMutationBody = BodyType<PatientSignUpBodies>
    export type PatientSignUpMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 患者サインアップAPI
 */
export const usePatientSignUp = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatientSignUpHook>>>, TError,{data: BodyType<PatientSignUpBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatientSignUpMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 通知された6桁の認証コードを入力し、サインアップの承認をする
- 本登録を担うこのAPIで患者情報を登録する
- 後続の保険証画像登録・支払い方法登録時にUPDATE処理をしたいため、下記テーブルにユーザーIDのみ保存する
  - 保険証画像
  - ストライプ情報

# 画面
- https://onl.tw/TGWSAeC
 * @summary 患者サインアップ確認API
 */
export const usePatientConfirmSignUpHook = () => {
        const patientConfirmSignUp = useCustomClient<SuccessResponse>();

        return (
    patientConfirmSignUpBodies: BodyType<PatientConfirmSignUpBodies>,
 ) => {
        return patientConfirmSignUp(
          {url: `/v1/patient/ConfirmSignUp`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: patientConfirmSignUpBodies
    },
          );
        }
      }
    


export const usePatientConfirmSignUpMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatientConfirmSignUpHook>>>, TError,{data: BodyType<PatientConfirmSignUpBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatientConfirmSignUpHook>>>, TError,{data: BodyType<PatientConfirmSignUpBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patientConfirmSignUp =  usePatientConfirmSignUpHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatientConfirmSignUpHook>>>, {data: BodyType<PatientConfirmSignUpBodies>}> = (props) => {
          const {data} = props ?? {};

          return  patientConfirmSignUp(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatientConfirmSignUpMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatientConfirmSignUpHook>>>>
    export type PatientConfirmSignUpMutationBody = BodyType<PatientConfirmSignUpBodies>
    export type PatientConfirmSignUpMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 患者サインアップ確認API
 */
export const usePatientConfirmSignUp = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatientConfirmSignUpHook>>>, TError,{data: BodyType<PatientConfirmSignUpBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatientConfirmSignUpMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 新規登録でソーシャルログインを選択した場合に、コールバックされるAPI
- 2024年5月時点では、以下のソーシャルログインをサポート
  - Google
  - Facebook
  - LINE
- 処理の流れ
  - 事前処理: クライアント側でソーシャルログインに成功し、認可コードを受け取ること
  - 1. その認可コードをパラメーターに、Cognitoから各種トークンを取得する（`/oauth2/token`を叩く）
  - 2. ソーシャルログインの情報を元に、患者情報を登録する
  - 3. 後続の保険証画像登録・支払い方法登録時にUPDATE処理をしたいため、下記テーブルにユーザーIDのみ保存する
    - 保険証画像
    - ストライプ情報
  - 4. 各種トークンを返却する
  - 5. クライアント側での遷移先を指定する
    - patientでは、サインアップ・サインインの遷移先が異なる
    - ソーシャルログインの場合、その2つの処理に違いがないため、クライアント側で遷移先を判別することができない
    - そのため、サーバー側のレスポンスをもとに遷移先を指定する

# 画面
- https://onl.tw/TGWSAeC
 * @summary 患者ソーシャルログインコールバックAPI
 */
export const usePatientSocialLoginCallbackHook = () => {
        const patientSocialLoginCallback = useCustomClient<SocialLoginCallbackResponseResponse>();

        return (
    patientSocialLoginCallbackBodies: BodyType<PatientSocialLoginCallbackBodies>,
 ) => {
        return patientSocialLoginCallback(
          {url: `/v1/patient/SocialLoginCallback`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: patientSocialLoginCallbackBodies
    },
          );
        }
      }
    


export const usePatientSocialLoginCallbackMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatientSocialLoginCallbackHook>>>, TError,{data: BodyType<PatientSocialLoginCallbackBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatientSocialLoginCallbackHook>>>, TError,{data: BodyType<PatientSocialLoginCallbackBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patientSocialLoginCallback =  usePatientSocialLoginCallbackHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatientSocialLoginCallbackHook>>>, {data: BodyType<PatientSocialLoginCallbackBodies>}> = (props) => {
          const {data} = props ?? {};

          return  patientSocialLoginCallback(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatientSocialLoginCallbackMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatientSocialLoginCallbackHook>>>>
    export type PatientSocialLoginCallbackMutationBody = BodyType<PatientSocialLoginCallbackBodies>
    export type PatientSocialLoginCallbackMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 患者ソーシャルログインコールバックAPI
 */
export const usePatientSocialLoginCallback = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatientSocialLoginCallbackHook>>>, TError,{data: BodyType<PatientSocialLoginCallbackBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatientSocialLoginCallbackMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- stripe側で提供されているエンドポイントを呼び出して、SetupIntentを作成する
  - stripe側のドキュメントは以下
  - https://stripe.com/docs/payments/setup-intents?locale=ja-JP
  - https://stripe.com/docs/api/setup_intents/create
- `clientSecret`はカード番号や有効期限を入力するフォームを表示するために利用される

# 画面URL
- https://onl.tw/UgQJrQV
 * @summary セットアップインテント作成API
 */
export const usePostPatientSetupIntentsHook = () => {
        const postPatientSetupIntents = useCustomClient<PostPatientSetupIntentsResponseResponse>();

        return (
    
 ) => {
        return postPatientSetupIntents(
          {url: `/v1/patient/setup_intents`, method: 'post'
    },
          );
        }
      }
    


export const usePostPatientSetupIntentsMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostPatientSetupIntentsHook>>>, TError,TVariables, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostPatientSetupIntentsHook>>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postPatientSetupIntents =  usePostPatientSetupIntentsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostPatientSetupIntentsHook>>>, TVariables> = () => {
          

          return  postPatientSetupIntents()
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostPatientSetupIntentsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostPatientSetupIntentsHook>>>>
    
    export type PostPatientSetupIntentsMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary セットアップインテント作成API
 */
export const usePostPatientSetupIntents = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostPatientSetupIntentsHook>>>, TError,TVariables, TContext>, }
) => {
    
      const mutationOptions = usePostPatientSetupIntentsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 登録する情報はクライアント<->stripe間でやり取りした一部情報となる
  - クライアントにてstripe apiを利用して支払い方法を登録する
  - そのためAPIで登録する情報はstripe apiからのレスポンスとなる
- ロジックはCREATEではなくUPDATE

# 画面URL
- https://onl.tw/UgQJrQV
- https://onl.tw/GpFUT9e
 * @summary 支払い方法登録API
 */
export const usePostPatientPaymentMethodHook = () => {
        const postPatientPaymentMethod = useCustomClient<SuccessResponse>();

        return (
    postPatientPaymentMethodBodies: BodyType<PostPatientPaymentMethodBodies>,
 ) => {
        return postPatientPaymentMethod(
          {url: `/v1/patient/payment_method`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postPatientPaymentMethodBodies
    },
          );
        }
      }
    


export const usePostPatientPaymentMethodMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostPatientPaymentMethodHook>>>, TError,{data: BodyType<PostPatientPaymentMethodBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostPatientPaymentMethodHook>>>, TError,{data: BodyType<PostPatientPaymentMethodBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postPatientPaymentMethod =  usePostPatientPaymentMethodHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostPatientPaymentMethodHook>>>, {data: BodyType<PostPatientPaymentMethodBodies>}> = (props) => {
          const {data} = props ?? {};

          return  postPatientPaymentMethod(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostPatientPaymentMethodMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostPatientPaymentMethodHook>>>>
    export type PostPatientPaymentMethodMutationBody = BodyType<PostPatientPaymentMethodBodies>
    export type PostPatientPaymentMethodMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 支払い方法登録API
 */
export const usePostPatientPaymentMethod = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostPatientPaymentMethodHook>>>, TError,{data: BodyType<PostPatientPaymentMethodBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePostPatientPaymentMethodMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 登録する情報はクライアント<->stripe間でやり取りした一部情報となる
  - クライアントにてstripe apiを利用して支払い方法を登録する(支払い方法登録時と同様)
  - そのためAPIで登録する情報はstripe apiからのレスポンスとなる

# 画面URL
- https://onl.sc/qfc2caA
 * @summary 支払い方法更新API
 */
export const usePatchPatientPaymentMethodHook = () => {
        const patchPatientPaymentMethod = useCustomClient<SuccessResponse>();

        return (
    patchPatientPaymentMethodBodies: BodyType<PatchPatientPaymentMethodBodies>,
 ) => {
        return patchPatientPaymentMethod(
          {url: `/v1/patient/payment_method`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchPatientPaymentMethodBodies
    },
          );
        }
      }
    


export const usePatchPatientPaymentMethodMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchPatientPaymentMethodHook>>>, TError,{data: BodyType<PatchPatientPaymentMethodBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchPatientPaymentMethodHook>>>, TError,{data: BodyType<PatchPatientPaymentMethodBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchPatientPaymentMethod =  usePatchPatientPaymentMethodHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchPatientPaymentMethodHook>>>, {data: BodyType<PatchPatientPaymentMethodBodies>}> = (props) => {
          const {data} = props ?? {};

          return  patchPatientPaymentMethod(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchPatientPaymentMethodMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchPatientPaymentMethodHook>>>>
    export type PatchPatientPaymentMethodMutationBody = BodyType<PatchPatientPaymentMethodBodies>
    export type PatchPatientPaymentMethodMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 支払い方法更新API
 */
export const usePatchPatientPaymentMethod = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchPatientPaymentMethodHook>>>, TError,{data: BodyType<PatchPatientPaymentMethodBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchPatientPaymentMethodMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 患者側で日程、医師、それに付帯する情報をもとに予約枠を作成する。
  予約した時間の予約可能枠を減らす。
- 事前問診回答の保存
  - 事前問診回答の仕様は以下
  - https://www.notion.so/7da0d36a3b344aa58ac0060332c36d72
 * @summary 予約作成API
 */
export const usePostConsultationsHook = () => {
        const postConsultations = useCustomClient<SuccessPostResponseIdResponse>();

        return (
    postConsultationsBodies: BodyType<PostConsultationsBodies>,
 ) => {
        return postConsultations(
          {url: `/v1/patient/consultations`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postConsultationsBodies
    },
          );
        }
      }
    


export const usePostConsultationsMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostConsultationsHook>>>, TError,{data: BodyType<PostConsultationsBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostConsultationsHook>>>, TError,{data: BodyType<PostConsultationsBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postConsultations =  usePostConsultationsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostConsultationsHook>>>, {data: BodyType<PostConsultationsBodies>}> = (props) => {
          const {data} = props ?? {};

          return  postConsultations(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostConsultationsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostConsultationsHook>>>>
    export type PostConsultationsMutationBody = BodyType<PostConsultationsBodies>
    export type PostConsultationsMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 予約作成API
 */
export const usePostConsultations = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostConsultationsHook>>>, TError,{data: BodyType<PostConsultationsBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePostConsultationsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- patientNumbersに基づいてORCAへ患者を作成する
 * @summary orcaへの患者登録API
 */
export const usePostOrcaPatientHook = () => {
        const postOrcaPatient = useCustomClient<SuccessResponseResponse>();

        return (
    postOrcaPatientBodies: BodyType<PostOrcaPatientBodies>,
 ) => {
        return postOrcaPatient(
          {url: `/v1/patient/orca`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postOrcaPatientBodies
    },
          );
        }
      }
    


export const usePostOrcaPatientMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrcaPatientHook>>>, TError,{data: BodyType<PostOrcaPatientBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrcaPatientHook>>>, TError,{data: BodyType<PostOrcaPatientBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postOrcaPatient =  usePostOrcaPatientHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOrcaPatientHook>>>, {data: BodyType<PostOrcaPatientBodies>}> = (props) => {
          const {data} = props ?? {};

          return  postOrcaPatient(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostOrcaPatientMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOrcaPatientHook>>>>
    export type PostOrcaPatientMutationBody = BodyType<PostOrcaPatientBodies>
    export type PostOrcaPatientMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary orcaへの患者登録API
 */
export const usePostOrcaPatient = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrcaPatientHook>>>, TError,{data: BodyType<PostOrcaPatientBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePostOrcaPatientMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * 同医師の同時刻の診察情報を取得するAPI
 * @summary 同医師同時刻診察取得API
 */
export const useGetSameTimeConsultationsHook = () => {
        const getSameTimeConsultations = useCustomClient<GetSameTimeConsultations200>();

        return (
    params: GetSameTimeConsultationsParams,
 signal?: AbortSignal
) => {
        return getSameTimeConsultations(
          {url: `/v1/consultations/sameTime`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetSameTimeConsultationsQueryKey = (params: GetSameTimeConsultationsParams,) => [`/v1/consultations/sameTime`, ...(params ? [params]: [])] as const;
  

    
export const useGetSameTimeConsultationsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetSameTimeConsultationsHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(params: GetSameTimeConsultationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSameTimeConsultationsHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSameTimeConsultationsHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSameTimeConsultationsQueryKey(params);

  const getSameTimeConsultations =  useGetSameTimeConsultationsHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSameTimeConsultationsHook>>>> = ({ signal }) => getSameTimeConsultations(params, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetSameTimeConsultationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSameTimeConsultationsHook>>>>
export type GetSameTimeConsultationsQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary 同医師同時刻診察取得API
 */
export const useGetSameTimeConsultations = <TData = Awaited<ReturnType<ReturnType<typeof useGetSameTimeConsultationsHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 params: GetSameTimeConsultationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSameTimeConsultationsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetSameTimeConsultationsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * 診察室ステータス情報を取得するAPI
 * @summary 診察室ステータス取得API
 */
export const useGetConsultationRoomStatusHook = () => {
        const getConsultationRoomStatus = useCustomClient<GetConsultationRoomStatus200>();

        return (
    params: GetConsultationRoomStatusParams,
 signal?: AbortSignal
) => {
        return getConsultationRoomStatus(
          {url: `/v1/consultationRoomStatuses`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetConsultationRoomStatusQueryKey = (params: GetConsultationRoomStatusParams,) => [`/v1/consultationRoomStatuses`, ...(params ? [params]: [])] as const;
  

    
export const useGetConsultationRoomStatusQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetConsultationRoomStatusHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(params: GetConsultationRoomStatusParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConsultationRoomStatusHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConsultationRoomStatusHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsultationRoomStatusQueryKey(params);

  const getConsultationRoomStatus =  useGetConsultationRoomStatusHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetConsultationRoomStatusHook>>>> = ({ signal }) => getConsultationRoomStatus(params, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetConsultationRoomStatusQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetConsultationRoomStatusHook>>>>
export type GetConsultationRoomStatusQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary 診察室ステータス取得API
 */
export const useGetConsultationRoomStatus = <TData = Awaited<ReturnType<ReturnType<typeof useGetConsultationRoomStatusHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 params: GetConsultationRoomStatusParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConsultationRoomStatusHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetConsultationRoomStatusQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

