import { 
  nameRegex, 
  katakanaRegex, 
  phoneNumberRegex, 
  postalCodeRegex, 
  addressRegex 
} from "util/Validation";

import { z } from "zod";

const gender = ["MALE", "FEMALE"] as const;

export const UserRegisterSchema = z.object({
  name: z
    .string({ required_error: "氏名は必須入力です" })
    .regex(nameRegex, "氏名は必須入力です"),
  nameKana: z
    .string({ required_error: "氏名(カナ)は必須入力です" })
    .regex(katakanaRegex, "全てカタカナで入力してください"),
  email: z
    .string({ required_error: "メールアドレスは必須入力です" })
    .email({ message: "正しいメールアドレスを入力してください" }),
  postalCode: z
    .string({ required_error: "郵便番号は必須入力です" })
    .regex(postalCodeRegex, "7桁の半角数字で入力してください"),
  prefecture: z.string({ required_error: "都道府県を選択してください" }),
  address: z
    .string({ required_error: "住所は必須入力です" })
    .regex(addressRegex, "住所は必須入力です"),
  phoneNumber: z
    .string({ required_error: "電話番号は必須入力です" })
    .regex(phoneNumberRegex, "10桁または11桁の半角数字で入力してください"),
  gender: z.enum(gender),
  birthDay: z
    .string({ required_error: "生年月日は必須入力です" })
    .refine((value) => isValidDate(value), {
      message: "有効な日付を入力してください",
    })
    .refine((value) => isFutureDate(value), {
      message: "本日より過去の日付を入力してください",
    }),
});

export type UserRegisterSchemaType = z.infer<typeof UserRegisterSchema>;

const isValidDate = (dateString: string) => {
  // 日付のパターン (YYYY-MM-DD)
  const pattern = /^\d{4}-\d{2}-\d{2}$/;

  // 正しいパターンでない場合は無効な日付
  if (!pattern.test(dateString)) return false;

  // 日付オブジェクトを作成して妥当性をチェック
  const parts = dateString.split("-");
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  const date = new Date(year, month - 1, day); // 月は0から始まるため -1 する

  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
};

const isFutureDate = (dateString: string) => {
  const today = new Date();
  const selectedDate = new Date(dateString);
  if (selectedDate > today) {
    return false;
  } else {
    return true;
  }
};
