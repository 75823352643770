import React, { forwardRef } from "react";

import {
  FormControl,
  OutlinedInput,
  Stack,
  Typography,
  Box,
} from "@mui/material";

import { ErrorTypography } from "../Typography";

export type TextInputProps = {
  label: string;
  type: string;
  placeholder: string;
  required: boolean;
  error: boolean;
  errorText?: string;
  defaultValue?: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  sx?: any;
};

export const TextField = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      label,
      type,
      placeholder,
      required,
      error,
      errorText,
      defaultValue,
      disabled,
      onChange,
      sx,
      ...field
    },
    ref
  ) => {
    return (
      <FormControl variant="filled" sx={{width: '100%'}}>
        <Stack spacing={1} width="100%">
          <Stack direction="row" spacing={0.5}>
            <Typography variant="bold14">{label}</Typography>
            {required && <Box color="alert.100">*</Box>}
          </Stack>

          <OutlinedInput
            sx={sx}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            ref={ref}
            defaultValue={defaultValue || ""}
            onChange={onChange}
            inputProps={{
              style: {
                padding: "13px 16px",
              },
              lang: "ja",
            }}
            {...field}
          />
          {error && errorText && <ErrorTypography errorText={errorText} />}
        </Stack>
      </FormControl>
    );
  }
);
