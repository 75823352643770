import { useCallback, useEffect, useRef, useState } from "react";

import { toast } from "react-toastify";
import Video, {
  ConnectOptions,
  RemoteAudioTrack,
  RemoteVideoTrack,
  Room,
  LocalTrack,
} from "twilio-video";

// @ts-ignore
window.TwilioVideo = Video;

const mediaErrors = [
  'NotAllowedError',
  'NotFoundError',
  'NotReadableError',
  'OverconstrainedError',
  'TypeError'
];

export const useRoom = (options?: ConnectOptions) => {
  const [room, setRoom] = useState<Room | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const [hasConnected, setHasConnected] = useState(false);
  const [isPatientVideoOff, setIsPatientVideoOff] = useState(false);
  const [remoteVideoTrack, setRemoteVideoTrack] =
    useState<RemoteVideoTrack | null>(null);
  const [remoteAudioTrack, setRemoteAudioTrack] =
    useState<RemoteAudioTrack | null>(null);
  const optionsRef = useRef(options);

  useEffect(() => {
    optionsRef.current = options;
  }, [options]);

  const connect = useCallback((token: string, roomName: string, localTracks: LocalTrack[]) => {
    return Video.connect(token, {
      name: roomName,
      tracks: localTracks,
    }).then(
      (newRoom) => {
        setRoom(newRoom);
        const disconnect = () => newRoom.disconnect();

        newRoom.setMaxListeners(16);

        newRoom.once("disconnected", (error) => {
          setTimeout(() => setRoom(null));
          window.removeEventListener("beforeunload", disconnect);
          if (error) {
            toast.error("接続が切れました。環境の良いところで再度入室してください。");
          }
        });

        // 参加者が退出したことを検知
        newRoom.on('participantDisconnected', _ => {
          if(newRoom.participants.size === 0){
            setHasConnected(false)
          }
        });

        // @ts-ignore
        window.twilioRoom = newRoom;

        newRoom.localParticipant.videoTracks.forEach((publication) =>
          publication.setPriority("low")
        );

        // Add a listener to disconnect from the room when a user closes their browser
        window.addEventListener("beforeunload", disconnect);

        newRoom.on("trackDisabled", (track) => {
          if(newRoom.participants.size > 0){
            if(track.kind === "video") {
              setIsPatientVideoOff(true)
            } else if(track.kind === "audio") {
              console.log("###audio", track.kind)
            }
            
          }
        })

        newRoom.on("trackEnabled", (track) => {
          if(newRoom.participants.size > 0){
            if(newRoom.participants.size > 0){
              if(track.kind === "video") {
                setIsPatientVideoOff(false)
              }
            }
          }
        })

        // Log new Participants as they connect to the Room
        newRoom.on("participantConnected", (participant) => {
          setIsConnecting(true);
          setHasConnected(true)
          participant.on("trackSubscribed", (track) => {
            if (track.kind === "video") setRemoteVideoTrack(track);
            if (track.kind === "audio") setRemoteAudioTrack(track);
          });
        });

        // Log any Participants already connected to the Room
        newRoom.participants.forEach((participant) => {
          setIsConnecting(true);
          setHasConnected(true)
          participant.on("trackSubscribed", (track) => {
            if (track.kind === "video") setRemoteVideoTrack(track);
            if (track.kind === "audio") setRemoteAudioTrack(track);
          });
        });
      },
      (error) => {
        toast.error(error);
        setIsConnecting(false);
        setHasConnected(false)
      }
    ).catch(error => {
      if (mediaErrors.includes(error.name)) {
        switch (error) {
          case "NotFoundError":
            toast.error("システムによって許可が拒否されました");
            break;
          case "NotAllowedError":
            toast.error("アクセス許可が拒否されました");
            break;
          default:
            toast.error(error);
            break;
        }
        console.error('Failed to acquire media:', error.name, error.message);
      }
      if ('code' in error) {
        toast.error(error);
        console.error('Failed to join Room:', error.code, error.message);
      }
    });
  }, []);

  return { room, isConnecting, hasConnected, connect, remoteVideoTrack, remoteAudioTrack, isPatientVideoOff };
};
