import { Stepper as MuiStepper, Step, StepLabel } from "@mui/material";
import { StepperDoneIcon } from "components/atoms/Icon/StepperDoneIcon";

export type Props = {
  activeStep: 0 | 1 | 2;

};

const stepStyle = {
  "& .MuiStepLabel-iconContainer": {
    ".MuiStepIcon-text": {
      fill: "#728DE3",
      fontSize: "14px",
    },
    "& .MuiStepIcon-root": {
      color: "func.10",
      width: 32,
      height: 32,
    },
  },
  "& .Mui-disabled": {
    ".MuiStepIcon-text": {
      fill: "#728DE3",
      fontSize: "14px",
    },
    ".MuiStepIcon-root": {
      color: "func.10",
      width: 32,
      height: 32,
    },
    color: '#C2CEF3'
  },
  "& .Mui-active": {
    ".MuiStepIcon-text": {
      fill: "#fff",
      fontSize: "14px",
      color: '#355CD7'
    },
    ".MuiStepIcon-root": {
      color: "#355CD7",
      width: 32,
      height: 32,
    },
    color: '#355CD7'
  },
  "& .Mui-completed": {
    ".MuiStepIcon-text": {
      fill: "#fff",
      fontSize: "14px",
    },
    ".MuiStepIcon-root": {
      color: "func.70",
      width: 32,
      height: 32,
    },
    color: '#355CD7'
  },
};

export const Stepper = ({ activeStep }: Props) => {
  const steps = ["1", "3"];

  return (
    <MuiStepper activeStep={activeStep} alternativeLabel sx={stepStyle}>
      {steps.map((label) => (
        <Step key={label} completed={false}>
          <StepLabel></StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};

export const ConsultationsStepper = ({ activeStep }: Props) => {
  const steps = ["1", "2", "3"];
  const stepLabelList = ['事前問診', '右目の登録', '左目の登録'];

  return (
    <MuiStepper activeStep={activeStep} alternativeLabel sx={stepStyle}>
      {steps.map((label, index) => (
        <Step key={label} completed={index < activeStep}>
          <StepLabel icon={index < activeStep ? <StepperDoneIcon /> : null}>
            {stepLabelList[index]}
          </StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};