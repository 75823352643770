import 'i18n/config';
import { useEffect } from "react";

import { RUM_POOL_ID, RUM_APPLICATION_ID } from "util/env";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PatientScreen } from "Screen";
import { AwsRum, AwsRumConfig } from "aws-rum-web";
import { I18nProvider } from "i18n/I18nContext";
import { AuthContextProvider } from "providers/AuthContextProvider";
import { MobileTypeContextProvider } from "providers/MobileTypeContextProvider";
import { VideoProvider } from "providers/VideoProvider";
import TagManager from "react-gtm-module";
import { RecoilRoot } from "recoil";

import { ThemeProviderWrap } from "./providers/ThemeProviderWrap";

const queryClient = new QueryClient();

function App() {
  const gtmId = "GTM-M4N4JM8C"; // TODO: use env

  useEffect(() => {
    TagManager.initialize({
      gtmId,
    });

    try {
      const config: AwsRumConfig = {
        sessionSampleRate: 1,
        identityPoolId: RUM_POOL_ID,
        endpoint: "https://dataplane.rum.ap-northeast-1.amazonaws.com",
        telemetries: ["performance", "errors", "http"],
        allowCookies: true,
        enableXRay: false,
      };

      const APPLICATION_ID: string = RUM_APPLICATION_ID;
      const APPLICATION_VERSION: string = "1.0.0";
      const APPLICATION_REGION: string = "ap-northeast-1";

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const awsRum: AwsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
      );
    } catch (error) {
      console.error("Failed to initialize AWS RUM:", error);
    }
  }, [gtmId]);

  return (
    <QueryClientProvider client={queryClient}>
      <I18nProvider>
        <AuthContextProvider>
          <RecoilRoot>
            <VideoProvider>
              <ThemeProviderWrap>
                <MobileTypeContextProvider>
                  <PatientScreen />
                </MobileTypeContextProvider>
              </ThemeProviderWrap>
            </VideoProvider>
          </RecoilRoot>
        </AuthContextProvider>
      </I18nProvider>
    </QueryClientProvider>
  );
}

export default App;
