import { useEffect, useState, useCallback } from "react";

import { PatchPatientBodyBodies } from "api-client";

export const useSessionStorage = (
  key: "user"
): [PatchPatientBodyBodies | null, (value: PatchPatientBodyBodies) => void] => {
  const temp = sessionStorage.getItem(key);
  const [userData, setUserData] = useState<PatchPatientBodyBodies | null>(
    JSON.parse(temp as string)
  );

  useEffect(() => {
    const handleStorageEvent = (event: StorageEvent) => {
      if (event.key === key) {
        setUserData(event.newValue as PatchPatientBodyBodies | null);
      }
    };

    window.addEventListener("storage", handleStorageEvent);

    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, [key]);

  const updateUserData = useCallback(
    (newValue: PatchPatientBodyBodies) => {
      const temp = JSON.stringify(newValue);
      sessionStorage.setItem(key, temp);
      setUserData(newValue);
    },
    [key]
  );

  return [userData, updateUserData];
};
