import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { Stack, Typography } from "@mui/material";

export type Props = {
  message: string;
};

export const Message = ({ message }: Props) => {
  return (
    <Stack
      direction="row"
      sx={{ backgroundColor: "alert.50" }}
      spacing={0.5}
      paddingLeft="6px"
      paddingY={0.5}
      alignItems="center"
      justifyItems="center"
      display="flex"
      borderRadius="6px"
    >
      <WarningAmberRoundedIcon
        sx={{ color: "alert.100", height: "13px", width: "13px" }}
      />
      <Typography variant="regular12" color="alert.100">
        {message}
      </Typography>
    </Stack>
  );
};
